/**
 *
 * Checklists
 *
 */
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { AbridgedChecklist, IChecklistSetting } from 'types/types';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
} from '@mui/material';
import { RiCloseLine, RiDeleteBackLine } from 'react-icons/ri';
import { DatePicker } from '@mui/x-date-pickers-pro';
import moment, { Moment } from 'moment';
import { ResetAndSaveButtons } from 'app/components/ResetAndSaveButtons';

interface Props {
  onClose: () => void;
  setting: IChecklistSetting;
  checklist: AbridgedChecklist;
  onSave: (date: string) => void;
}

export function DueDateDialog(props: Props) {
  const { onClose, onSave, checklist, setting } = props;
  const [value, setValue] = useState<Date | any>(setting.due_date);

  const date = useMemo(() => {
    if (!value) return null;

    return moment(value, 'YYYY-MM-DD HH:mm:ss');
  }, [value]);

  useEffect(() => {
    if (!setting) return;

    const { due_date } = setting || {};
    setValue(due_date);
  }, [setting]);

  return (
    <Dialog
      maxWidth={'sm'}
      fullWidth
      open={!!checklist && !!setting}
      onClose={onClose}
    >
      <DialogTitle>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box>Set scheduled date</Box>
          <IconButton onClick={onClose} edge={'end'} size={'large'}>
            <RiCloseLine />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Stack>
          <DatePicker
            minDate={moment()}
            format="DD/MM/YYYY"
            onChange={(value, keyboardInputValue) =>
              setValue((value as Moment).format('YYYY-MM-DD HH:mm:ss'))
            }
            value={date}
            slotProps={{ textField: {} }}
          />
          {!!date && (
            <Button
              size={'small'}
              color={'error'}
              variant={'text'}
              endIcon={<RiDeleteBackLine />}
              onClick={() => setValue(null)}
            >
              Clear scheduled date
            </Button>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Box width={'100%'}>
          <ResetAndSaveButtons
            onSave={() => {
              onSave(value);
              onClose();
            }}
          />
        </Box>
      </DialogActions>
    </Dialog>
  );
}
