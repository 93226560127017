import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { ResetAndSaveButtons } from '../ResetAndSaveButtons';
import moment from 'moment';

interface Props {
  size: 'small' | 'medium' | 'large';
  saving?: boolean;
  lastSaved: string;
}

export function RiskSaveBar(props: Props) {
  const { size = 'small', lastSaved, saving = false } = props;
  return (
    <Box>
      <Stack
        direction={'row'}
        spacing={2}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Typography sx={{ color: '#71718B' }}>
          Last saved on the {moment(lastSaved).format('D MMM Y [at] HH:mm')}
        </Typography>

        <ResetAndSaveButtons
          saving={saving}
          size={size}
          saveText="Save changes"
        />
      </Stack>
    </Box>
  );
}
