import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { TemplatesState } from './types';
import { IDocument } from 'types/types';
import { ResponseErrorPayload } from 'utils/request';
import { getApiCallInitialState } from 'types/ApiCall';

export const initialState: TemplatesState = {
  loadTemplates: getApiCallInitialState<IDocument[]>('list'),
};

export const slice = createSlice({
  name: 'templates',
  initialState,
  reducers: {
    init(state, action: PayloadAction<void>) {
      state.loadTemplates = initialState.loadTemplates;
    },
    loadTemplatesRequest(state, action: PayloadAction<{ [key: string]: any }>) {
      state.loadTemplates.loading = true;
      state.loadTemplates.error = undefined;
    },
    loadTemplatesSuccess(state, action: PayloadAction<{ data: IDocument[] }>) {
      state.loadTemplates.loading = false;
      state.loadTemplates.error = undefined;
      state.loadTemplates.data = action.payload.data;
    },
    loadTemplatesFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.loadTemplates.loading = false;
      state.loadTemplates.error = action.payload;
    },
  },
});

export const { actions: templatesActions } = slice;
