import React, { useMemo, useState } from 'react';
import {
  Avatar,
  Box,
  Grid,
  Stack,
  Typography,
  IconButton,
  Autocomplete,
  TextField,
} from '@mui/material';
import { useFormikContext } from 'formik';
import { RiCloseCircleLine } from 'react-icons/ri';
import { getInitials } from 'utils/initials';
import { ActionRegisterCommittee } from 'app/widgets/ActionRegister/slice/types';
import { TitledField } from 'app/components/TitledField';
import { ActionRegisterFormType } from '../ActionRegisterDetailsForm';
import { DashedButton } from 'app/components/DashedButton';
import { Link } from 'react-router-dom';

interface Props {
  committees: ActionRegisterCommittee[];
  committeePath?: string;
}

export function AssignOwnerField(props: Props) {
  const { committees, committeePath } = props;

  const { setFieldValue, values } = useFormikContext<ActionRegisterFormType>();

  const members = useMemo(() => {
    return committees.find(c => c.id === values.committee_id)?.members || [];
  }, [committees, values.committee_id]);

  const filteredMembers = useMemo(() => {
    return members.filter(m => !values.member_ids.includes(m.member_id));
  }, [members, values.member_ids]);

  const [searchValue, setSearchValue] = useState(null);
  const [inputValue, setInputValue] = useState('');

  const handleAddMember = member => {
    const newMembers = [...values.member_ids, member.id];
    setFieldValue('member_ids', newMembers);
    setSearchValue(null);
    setInputValue('');
  };

  const handleRemoveMember = memberId => {
    const filteredIds = values.member_ids.filter(id => id !== memberId);
    setFieldValue('member_ids', filteredIds);
  };

  return (
    <>
      {!!members.length ? (
        <TitledField title="Assignees">
          <Stack>
            <Typography variant="body2">
              Assign an owner who is directly involved in or responsible for the
              action previously outlined.
            </Typography>
            <Typography variant="body2">
              Search and select below the team members for this action
            </Typography>

            <Box>
              <Grid container>
                <Grid item xs={12} md={6}>
                  <Autocomplete
                    options={filteredMembers.map(m => ({
                      id: m.member_id,
                      first_name: m.first_name,
                      last_name: m.last_name,
                    }))}
                    getOptionLabel={option =>
                      `${option.first_name} ${option.last_name}`
                    }
                    renderInput={params => (
                      <TextField {...params} label="Select member name" />
                    )}
                    value={searchValue}
                    onChange={(event, newValue) => {
                      if (newValue) {
                        handleAddMember(newValue);
                      }
                    }}
                    inputValue={inputValue}
                    onInputChange={(event, newInputValue) => {
                      setInputValue(newInputValue);
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  {values.member_ids.length ? (
                    <Stack spacing={1}>
                      {values.member_ids.map(id => {
                        const member = members.find(m => m.member_id === id);
                        if (!member) return null;
                        return (
                          <Stack
                            key={id}
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Stack direction="row" alignItems="center">
                              <Avatar
                                sx={{
                                  backgroundColor: 'common.beige',
                                  color: 'common.black',
                                  width: 30,
                                  height: 30,
                                }}
                              >
                                {getInitials(
                                  `${member.first_name} ${member.last_name}`,
                                )}
                              </Avatar>
                              <Typography fontWeight="bold" variant="body2">
                                {`${member.first_name} ${member.last_name}`}
                              </Typography>
                            </Stack>
                            <IconButton
                              sx={{ color: 'error.main' }}
                              onClick={() =>
                                handleRemoveMember(member.member_id)
                              }
                            >
                              <RiCloseCircleLine />
                            </IconButton>
                          </Stack>
                        );
                      })}
                    </Stack>
                  ) : (
                    <Stack
                      height={'100%'}
                      alignItems={'center'}
                      justifyContent={'center'}
                    >
                      <Typography variant="body2" color={'textSecondary'}>
                        No members assigned
                      </Typography>
                    </Stack>
                  )}
                </Grid>
              </Grid>
            </Box>
          </Stack>
        </TitledField>
      ) : (
        <DashedButton
          component={Link}
          path={committeePath}
          description={
            'No members have been assigned to the selected committee yet.'
          }
          ctaText={'Go to the Committees page to add members'}
        />
      )}
    </>
  );
}
