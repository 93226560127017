/**
 *
 * ActionRegisterDetailsForm
 *
 */
import React from 'react';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { IActionRegisterItem } from 'types/types';
import { Form as FormikForm, Formik } from 'formik';
import * as Yup from 'yup';
import { RiCloseLine } from 'react-icons/ri';
import { ActionRegisterCommittee } from '../../slice/types';
import moment from 'moment-timezone';
import { WizardContainer } from 'app/components/WizardContainer';
import { ActionDetailsFields } from '../ActionDetailsFields/ActionDetailsFields';
import { ResetAndSaveButtons } from 'app/components/ResetAndSaveButtons';

interface Props {
  action: IActionRegisterItem | undefined;
  onSave: (payload: ActionRegisterFormType) => void;
  onClose: () => void;
  saving: boolean;
  committees: ActionRegisterCommittee[];
}

const schema = Yup.object().shape({
  description: Yup.string().required('This field is required'),
  committee_id: Yup.number().required('This field is required'),
  due_at: Yup.date()
    .typeError('Due date is required')
    .required('Due date is required'),
});

export type ActionRegisterFormType = Partial<IActionRegisterItem> & {
  member_ids?: (string | number)[];
};

export function ActionRegisterDetailsForm(props: Props) {
  const { action, onSave, onClose, saving, committees } = props;

  return (
    <Formik<ActionRegisterFormType>
      validateOnMount
      validationSchema={schema}
      initialValues={{ ...action }}
      validateOnChange
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true);
        onSave({
          ...values,
          due_at: moment.utc(values.due_at).format('YYYY-MM-DD HH:mm:ss'),
        });
      }}
    >
      {formik => {
        const { setSubmitting, isSubmitting } = formik;
        if (isSubmitting && !saving) setSubmitting(false);
        return (
          <FormikForm>
            <WizardContainer sx={{ py: 0, px: 0 }}>
              <DialogTitle>
                <Stack
                  direction={'row'}
                  justifyContent={'space-between'}
                  alignItems="center"
                >
                  <Typography variant="h2">Edit action details</Typography>
                  <IconButton edge={'end'} size={'large'} onClick={onClose}>
                    <RiCloseLine />
                  </IconButton>
                </Stack>
              </DialogTitle>
              <DialogContent>
                <Stack spacing={4}>
                  <ActionDetailsFields committees={committees} />
                </Stack>
              </DialogContent>
              <DialogActions>
                <ResetAndSaveButtons saving={saving} />
              </DialogActions>
            </WizardContainer>
          </FormikForm>
        );
      }}
    </Formik>
  );
}
