import { Button, Stack } from '@mui/material';
import { useFormikContext } from 'formik';
import * as React from 'react';

interface Props {
  onSave?: () => void;
  onReset?: () => void;
  saveText?: string;
  resetText?: string;
  size?: 'small' | 'medium' | 'large';
  saving?: boolean;
}

export function ResetAndSaveButtons(props: Props) {
  const {
    saving,
    size = 'medium',
    resetText = 'Reset changes',
    saveText = 'Save',
    onSave,
    onReset,
  } = props;

  const { dirty, isSubmitting } = useFormikContext();

  return (
    <>
      <Stack direction={'row'} justifyContent={'flex-end'}>
        <Button
          size={size}
          variant={'outlined'}
          type="reset"
          onClick={onReset}
          disabled={!dirty || isSubmitting || saving}
        >
          {resetText}
        </Button>
        <Button
          size={size}
          type={onSave ? 'button' : 'submit'}
          variant="contained"
          onClick={onSave}
          disabled={!dirty || isSubmitting || saving}
        >
          {saveText}
        </Button>
      </Stack>
    </>
  );
}
