import React from 'react';
import { IOrganisation } from 'types/types';
import { useCallback, useMemo } from 'react';
import { Box, Container, Stack, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useTemplatesSlice } from './slice/hook';
import { useEffectOnce } from 'utils/useEffectOnce';
import { LoadingIndicator } from 'app/components/LoadingIndicator';
import { Helmet } from 'react-helmet-async';
import { useHasPermission } from 'app/providers/AuthProvider/useHasPermission';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { Filename } from '../../components/Filename';
import { openFile } from '../../../utils/request';
import { selectTemplates } from './slice/selectors';
import { SectionHeader } from 'app/components/SectionHeader';
import { calculateDataTableHeight } from 'styles/theme';

interface Props {
  organisation: IOrganisation;
}

export function Templates(props: Props) {
  const { organisation } = props;
  const { loadTemplates } = useSelector(selectTemplates);
  const { actions } = useTemplatesSlice();
  const dispatch = useDispatch();

  const { hasPermission } = useHasPermission();

  useEffectOnce(() => {
    if (organisation) load();
  });

  const load = useCallback(() => {
    dispatch(
      actions.loadTemplatesRequest({
        organisation_id: organisation.id,
      }),
    );
  }, [organisation]);

  const rows = useMemo(() => {
    return loadTemplates.data.map(d => ({
      id: d.id,
      name: d.name,
      description: d.description,
      ...d.file_details,
    }));
  }, [loadTemplates.data]);

  const downloadable = hasPermission(
    'download-organisation-documents',
    'organisation',
    organisation.id,
  );

  const pageSize = 25;

  const showFooter = useMemo(() => {
    return rows.length > pageSize;
  }, [pageSize, rows]);

  if (!loadTemplates.data.length && loadTemplates.loading) {
    return <LoadingIndicator />;
  }

  return (
    <>
      <Helmet title={'Organisation templates'}>
        <meta
          name="description"
          content="Template documents for the organisation"
        />
      </Helmet>

      <SectionHeader title="Templates" />
      <Box my={4}>
        <Container>
          <Stack spacing={4}>
            <Stack spacing={2}>
              <Typography variant={'h3'}>Templates</Typography>
              <Typography>
                This section holds sample Templates provided to you as an
                Administrator (board members without Admin rights do not see
                this section). Documents are provided for your use (feel free to
                download and adopt them if they are useful to you), and vary
                depending on the plan you are on.
              </Typography>
            </Stack>

            {!!rows.length && (
              <Box
                sx={{
                  height: calculateDataTableHeight(rows.length, showFooter, 50),
                  maxHeight: '80vh',
                  width: '100%',
                }}
              >
                <DataGridPro
                  getRowHeight={() => 80}
                  slots={{
                    columnHeaders: React.forwardRef((props, ref) => (
                      <Box ref={ref} />
                    )),
                    noRowsOverlay: () => (
                      <>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '100%',
                          }}
                        >
                          <Typography>No documents attached</Typography>
                        </Box>
                      </>
                    ),
                  }}
                  hideFooter={!showFooter}
                  pagination={showFooter}
                  columns={[
                    {
                      field: 'name',
                      headerName: 'Name',
                      minWidth: 200,
                      flex: 0.8,
                      sortable: false,
                      renderCell: ({ row }) => (
                        <Box>
                          <Stack spacing={1}>
                            <Filename
                              sx={{
                                cursor: downloadable ? 'pointer' : 'unset',
                                pointerEvents: row.filename ? 'auto' : 'none',
                                '& .MuiTypography-body1': {
                                  fontWeight: 'bold',
                                },
                              }}
                              name={row.name || '[Name not set]'}
                              extension={row.extension || 'bin'}
                              onClick={ev => {
                                if (!downloadable) return;
                                ev.nativeEvent.stopImmediatePropagation();
                                openFile(row.url);
                              }}
                            />
                            {!!row.description && (
                              <Typography variant={'body2'}>
                                {row.description}
                              </Typography>
                            )}
                          </Stack>
                        </Box>
                      ),
                    },
                  ]}
                  rows={rows}
                />
              </Box>
            )}
          </Stack>
        </Container>
      </Box>
    </>
  );
}
