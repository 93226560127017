import React, { useRef, useState, useEffect } from 'react';
import { Formik, Form, FormikProps } from 'formik';
import * as Yup from 'yup';
import { Box, Button, Grid, Stack } from '@mui/material';
import moment from 'moment-timezone';
import { useNavigate } from 'react-router-dom';
import { BoardInfo } from './BoardInfo';
import { ScheduleMeeting } from './ScheduleMeeting';
import { getDateOfNextWorkingDay } from 'utils/getDateOfNextWorkingDay';

export interface StartBoardValues {
  committee_name: string;
  schedule_meeting: boolean;
  meeting: {
    name: string;
    description: string;
    location: string;
    date_start: moment.Moment;
    date_end: moment.Moment;
    length: number;
  };
}

interface Props {
  onSubmit: (payload: StartBoardValues) => void;
  onSkip: (payload: any) => void;
  saving: boolean;
}

const meetingSchema = Yup.object().shape({
  name: Yup.string().required('Meeting name is required'),
  date_start: Yup.date()
    .typeError('Start date and time are required')
    .required('Start date is required'),
  date_end: Yup.date()
    .typeError('End date and time are required')
    .required('End date is required'),
  length: Yup.number()
    .min(0.5, 'Meeting length must be at least 0.5 hours')
    .typeError('Meeting length must be at least 0.5 hours')
    .required('Meeting length is required'),
});

const schema = [
  {
    committee_name: Yup.string().required('Committee name is required'),
  },
  {
    schedule_meeting: Yup.boolean().required(),
    meeting: Yup.object().when('schedule_meeting', {
      is: true,
      then: meetingSchema,
      otherwise: Yup.object().nullable(),
    }),
  },
];

export function Wizard(props: Props) {
  const { onSkip, onSubmit, saving } = props;

  const [step, setStep] = useState<number>(0);

  const steps = [
    {
      label: 'Board Info',
      component: (
        <BoardInfo
          next={() => {
            setStep(1);
          }}
        />
      ),
    },
    {
      label: 'Schedule Meeting',
      component: (
        <ScheduleMeeting saving={saving} previous={() => setStep(0)} />
      ),
    },
  ];

  const navigate = useNavigate();

  const initialValues: StartBoardValues = {
    committee_name: 'Board',
    schedule_meeting: true,
    meeting: {
      name: '',
      description: '',
      location: '',
      date_start: getDateOfNextWorkingDay(),
      date_end: moment().add(2, 'hours'),
      length: 2,
    },
  };

  const formikRef = useRef<FormikProps<StartBoardValues>>(null);

  useEffect(() => {
    formikRef.current?.validateForm();
  }, [step]);

  const indexTab = steps.findIndex((tab, index) => index === step);
  const currentValidationSchema = Yup.object().shape(
    Object.assign({}, ...schema.slice(0, indexTab + 1)),
  );

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      validationSchema={currentValidationSchema}
      validateOnBlur
      validateOnChange
      onSubmit={(values: StartBoardValues, { setSubmitting }) => {
        setSubmitting(true);
        if (onSubmit) {
          if (!values.schedule_meeting) {
            onSkip(values);
          } else {
            onSubmit(values);
          }
        }

        setSubmitting(false);
      }}
    >
      {() => (
        <Form>
          <Stack spacing={4}>
            <Box>{steps[step > 1 ? 0 : step].component}</Box>

            <Box color={'grey.700'}>
              <Button
                variant={'text'}
                onClick={() => {
                  navigate('/');
                }}
                color={'inherit'}
              >
                Cancel setting up Boards
              </Button>
            </Box>
          </Stack>
        </Form>
      )}
    </Formik>
  );
}
