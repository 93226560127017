import React, { MouseEvent, useState } from 'react';
import {
  IconButton,
  Menu,
  MenuItem,
  Button,
  Box,
  Typography,
  Stack,
} from '@mui/material';
import { RiMore2Line } from 'react-icons/ri';
import { RiskAreaAction } from 'types/types';
import { useConfirm } from 'utils/useConfirm';
import { noop } from 'utils/noop';

interface Props {
  onRiskAreaAction: (action: RiskAreaAction) => void;
  onRename: () => void;
  canDelete?: boolean;
}

export default function RiskHeaderMenu(props: Props) {
  const { onRiskAreaAction, onRename, canDelete = false } = props;

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const confirm = useConfirm();

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'open-kebab' : undefined;

  const linkStyles = { color: 'primary.main', py: 1 };
  return (
    <>
      <IconButton aria-describedby={id} onClick={handleClick}>
        <RiMore2Line aria-label="See more options" />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem
          sx={linkStyles}
          onClick={() => {
            onRiskAreaAction({ action: 'move-up' });
            handleClose();
          }}
        >
          Move up
        </MenuItem>
        <MenuItem
          sx={linkStyles}
          onClick={() => {
            onRiskAreaAction({ action: 'move-down' });
            handleClose();
          }}
        >
          Move down
        </MenuItem>
        <MenuItem
          sx={linkStyles}
          onClick={() => {
            onRename();
            handleClose();
          }}
        >
          Rename
        </MenuItem>
        <MenuItem
          sx={linkStyles}
          onClick={() => {
            if (canDelete) {
              confirm({
                title: 'Delete risk category',
                description:
                  'Are you sure you want to delete this risk category?',
                allowClose: false,
              })
                .then(() => {
                  onRiskAreaAction({ action: 'remove' });
                })
                .catch(noop);
            } else {
              confirm({
                title: 'Delete risk category',
                description: (
                  <>
                    <Stack spacing={1}>
                      <Typography>
                        You cannot delete this risk category until all risks
                        have been cleared in that category. To clear a risk, you
                        can move or archive the risk.
                      </Typography>
                      <Typography>
                        To move a risk, open the risk and click the 'edit' tab.
                        Then choose from the dropdown the risk category you wish
                        to assign the risk to.
                      </Typography>
                      <Typography>
                        Alternatively, you can archive the risk via the
                        'Archive' tab within the risk.
                      </Typography>
                    </Stack>
                  </>
                ),
                confirmationText: 'Ok',
                dialogActionsProps: {
                  sx: {
                    justifyContent: 'end',
                  },
                },
                cancellationButtonProps: { sx: { display: 'none' } },
                allowClose: false,
              })
                .then(() => {})
                .catch(noop);
            }

            handleClose();
          }}
        >
          Delete risk category
        </MenuItem>
        {/*
        <MenuItem
          sx={linkStyles}
          disabled
          onClick={() => {
            handleClose();
          }}
        >
          Export
        </MenuItem>
          */}
      </Menu>
    </>
  );
}
