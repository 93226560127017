import {
  Box,
  Button,
  Grid,
  Stack,
  TextField as MuiTextField,
  Typography,
} from '@mui/material';
import { DateFieldWrapper } from 'app/components/DateFieldWrapper';
import { Field, useFormikContext } from 'formik';
import { Autocomplete, TextField } from 'formik-mui';
import React from 'react';
import { ActionRegisterCommittee } from '../../../slice/types';
import { ActionRegisterFormType } from '../index';
import { ResetAndSaveButtons } from 'app/components/ResetAndSaveButtons';
import { ActionDetailsFields } from '../../ActionDetailsFields/ActionDetailsFields';

interface Props {
  onClose?: () => void;
  committees: ActionRegisterCommittee[];
  next?: () => void;
  mode?: 'edit' | 'default';
  saving?: boolean;
}

export function ActionDetails(props: Props) {
  const { onClose, next, committees } = props;
  const { isValid } = useFormikContext<ActionRegisterFormType>();

  return (
    <>
      <Typography variant="h3">Action details</Typography>
      <Typography variant="body2">
        Provide a clear and concise description of the specific action to be
        undertaken. Include details such as objectives, key tasks, a deadline
        and responsible assignees to ensure clarity and accountability.
      </Typography>

      <ActionDetailsFields committees={committees} />
      <Box pt={2}>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <Button fullWidth variant="outlined" onClick={onClose}>
              Cancel
            </Button>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button fullWidth onClick={next} disabled={!isValid}>
              Next
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
