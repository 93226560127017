// basic react component in TS
import React from 'react';
import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { RadioButton } from '../../../../components/RadioButton';
import { Field, Form, Formik, useFormikContext } from 'formik';
import { IActionRegisterItem } from '../../../../../types/types';
import { ActionRegisterFormType } from '../ActionRegisterForm';
import { RiCloseLine } from 'react-icons/ri';
import { TextField } from 'formik-mui';
import { ResetAndSaveButtons } from '../../../../components/ResetAndSaveButtons';
import { WizardContainer } from 'app/components/WizardContainer';

interface Props {
  action: IActionRegisterItem | undefined;
  onSave: (payload: ActionRegisterFormType) => void;
  onClose: () => void;
  saving: boolean;
}

export function ActionRegisterStatusForm(props: Props) {
  const { action, onSave, onClose, saving } = props;
  return (
    <Formik
      initialValues={{
        ...action,
      }}
      onSubmit={values => {
        onSave(values);
      }}
    >
      {({ values, setFieldValue }) => {
        return (
          <Form>
            <WizardContainer sx={{ p: 0 }}>
              <DialogTitle>
                <Stack
                  direction={'row'}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                >
                  <Typography variant="h2">Action status</Typography>
                  <IconButton edge={'end'} size={'large'} onClick={onClose}>
                    <RiCloseLine />
                  </IconButton>
                </Stack>
              </DialogTitle>
              <DialogContent>
                <Stack spacing={4}>
                  <Stack>
                    <Box>
                      <Typography variant="body2">
                        The action: "{values.description}"
                        {!!values.members.length
                          ? ` is currently assigned to ${values.members
                              .map((o, index) => {
                                if (
                                  index === values.members.length - 1 &&
                                  index > 0
                                ) {
                                  return ` and ${o.first_name} ${o.last_name}`;
                                } else if (index > 0) {
                                  return `, ${o.first_name} ${o.last_name}`;
                                } else {
                                  return `${o.first_name} ${o.last_name}`;
                                }
                              })
                              .join('')}.`
                          : ' does not have assigned members.'}{' '}
                        Select below whether the action is Open or Closed
                      </Typography>
                    </Box>

                    <Box>
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                          <RadioButton
                            onChange={() => setFieldValue('status', 'open')}
                            checked={values.status === 'open'}
                            label={
                              <Stack
                                spacing={2}
                                justifyContent={'start'}
                                textAlign={'start'}
                              >
                                <Typography color={'primary.main'} variant="h5">
                                  Open
                                </Typography>
                                <Typography
                                  variant="body2"
                                  color={'common.black'}
                                >
                                  Choose this option if this action is still in
                                  progress
                                </Typography>
                              </Stack>
                            }
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <RadioButton
                            onChange={() => setFieldValue('status', 'closed')}
                            checked={values.status === 'closed'}
                            label={
                              <Stack
                                spacing={2}
                                justifyContent={'start'}
                                textAlign={'start'}
                              >
                                <Typography color={'primary.main'} variant="h5">
                                  Closed
                                </Typography>
                                <Typography
                                  variant="body2"
                                  color={'common.black'}
                                >
                                  Choose this option if this action has been
                                  completed or dismissed
                                </Typography>
                              </Stack>
                            }
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  </Stack>
                  {values.status === 'closed' && (
                    <Stack>
                      <Typography variant="body2">
                        Use the field below to add your closing notes (optional)
                      </Typography>
                      <Field
                        component={TextField}
                        name="notes"
                        label="Closing notes"
                        multiline
                        rows={4}
                        fullWidth
                      />
                    </Stack>
                  )}
                </Stack>
              </DialogContent>
              <DialogActions>
                <ResetAndSaveButtons saving={saving} />
              </DialogActions>
            </WizardContainer>
          </Form>
        );
      }}
    </Formik>
  );
}
