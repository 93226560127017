import { useConfirm as useConfirmBase } from 'material-ui-confirm';

export function useConfirm() {
  const confirmBase = useConfirmBase();

  const confirm: typeof confirmBase = options =>
    confirmBase({
      description: 'Are you sure?',
      confirmationText: 'Yes',
      cancellationText: 'No',
      cancellationButtonProps: {
        variant: 'outlined',
        size: 'small',
      },
      dialogActionsProps: {
        sx: {
          justifyContent: 'space-between',
        },
      },
      ...options,
    });

  return confirm;
}
