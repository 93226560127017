/**
 *
 * Item
 *
 */
import React, { useCallback, useEffect } from 'react';
import {
  Box,
  Button,
  CardMedia,
  Chip,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import { useConfirm } from 'utils/useConfirm';
import { RiAddCircleFill, RiDragDropFill } from 'react-icons/ri';
import Pin from '../../components/RiskRegisterItem/assets/pin_fill.svg';
import { useMeetingsSlice } from './slice/hook';
import { useDispatch, useSelector } from 'react-redux';
import { selectMeetings } from './slice/selectors';
import { IOrganisation, MinutesStatus } from 'types/types';
import { useParams } from 'react-router';
import { LoadingIndicator } from 'app/components/LoadingIndicator';
import moment from 'moment';
import { ConflictsTable } from 'app/components/ConflictsTable';
import {
  MeetingFormDialog,
  useMeetingFormWidget,
} from 'app/widgets/MeetingFormWidget';
import { Helmet } from 'react-helmet-async';
import { useHasPermission } from 'app/providers/AuthProvider/useHasPermission';
import { useNavigate } from 'react-router-dom';
import { AddToCalendar } from 'app/components/AddToCalendar';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { MeetingDocument } from './MeetingDocument';
import { noop } from 'lodash';
import { Delete, Download, Edit } from '@mui/icons-material';
import useHandleApiResponse from '../../../utils/useHandleApiResponse';
import FileUploadComponent from '../../components/FileUpload/FileUploadComponent';
import LinkRenderer from './LinkRenderer';
import { useSnackbar } from 'notistack';
import { formatParsedISO } from '../../../utils/formatParsedISO';
import { MeetingUpdateForm } from './MeetingUpdateForm';
import key from 'weak-key';
import { MeetingSettings } from './MeetingSettings';
import { CustomAccordion } from 'app/components/CustomAccordion';

import { ActionRegister } from 'app/widgets/ActionRegister';
import { useSettings } from 'app/providers/SettingsProvider';

import { MeetingLogOptions } from './MeetingLogOptions';
import { TitledField } from 'app/components/TitledField';
import { useEffectOnChange } from '../../../utils/useEffectOnChange';
import { useCanCreateMeetings } from './useCanCreateMeetings';
import { getApiUrl, openFile } from '../../../utils/request';

interface Props {
  organisation: IOrganisation;
}

export function Item(props: Props) {
  const { organisation } = props;

  const { actions } = useMeetingsSlice();
  const {
    loadMeeting,
    deleteMeeting,
    deleteDocument,
    reorderDocuments,
    saveDocument,
    createMeetingUpdate,
    deleteMeetingUpdate,
    updateMeetingSettings,
  } = useSelector(selectMeetings);
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const confirm = useConfirm();
  const snackbar = useSnackbar();
  const { hasPermission } = useHasPermission();
  const canCreateMeetings = useCanCreateMeetings(organisation);

  const { id } = useParams();

  const { open, close, isOpen } = useMeetingFormWidget();

  const deleteDocumentCB = useCallback(
    (documentId: number) => {
      confirm({
        title: 'Delete document',
      })
        .then(() => {
          dispatch(
            actions.deleteDocumentRequest({ meetingId: +id, documentId }),
          );
        })
        .catch(noop);
    },
    [loadMeeting.data],
  );

  const saveDocumentCB = useCallback(
    (p: {
      name: string;
      file: File;
      description: string;
      documentId?: number;
      category: 'agenda' | 'minutes' | 'previous-minutes' | 'working-paper';
    }) => {
      dispatch(actions.saveDocumentRequest({ meetingId: +id, ...p }));
    },
    [loadMeeting.data],
  );

  const { settings, setSetting } = useSettings();
  const reorderDocumentsCB = useCallback(
    (oldIndex: number, newIndex: number, row: any) => {
      dispatch(
        actions.reorderDocumentsRequest({
          meetingId: +id,
          oldIndex,
          newIndex,
          row,
        }),
      );
    },
    [loadMeeting.data],
  );

  const load = useCallback(() => {
    dispatch(actions.loadMeetingRequest({ id: +id }));
  }, [organisation, id]);

  useEffectOnChange(
    () => {
      if (loadMeeting.error) {
        if (loadMeeting.error.code === 401) return;
        navigate('../', { replace: true });
      }
    },
    loadMeeting.loading,
    false,
  );

  useEffectOnChange(
    () => {
      load();
    },
    id,
    (id: string) => !!id,
  );

  useEffect(() => {
    load();
    return () => {
      dispatch(actions.loadMeetingInit());
    };
  }, [load]);

  useHandleApiResponse(deleteMeeting, 'Meeting deleted', {
    onSuccess: () => {
      close();
      navigate('../upcoming', { replace: true });
    },
    errorMessage: 'Unable to delete a meeting',
  });

  useHandleApiResponse(saveDocument, 'Document saved', {
    onSuccess: () => {
      load();
    },
  });

  useHandleApiResponse(deleteDocument, null, {
    onSuccess: () => {
      load();
    },
  });

  useHandleApiResponse(reorderDocuments, null, {
    onSuccess: () => {
      load();
    },
  });

  useHandleApiResponse(createMeetingUpdate, 'Meeting activity updated', {
    onSuccess: () => {
      load();
    },
  });

  useHandleApiResponse(deleteMeetingUpdate, 'Meeting activity updated', {
    onSuccess: () => {
      load();
    },
  });

  useHandleApiResponse(updateMeetingSettings, null, {
    onSuccess: () => {
      load();
    },
  });

  const serveAllDocuments = useCallback(() => {
    openFile(getApiUrl(`meetings/${id}/all-documents`));
  }, [loadMeeting.data]);

  if (!loadMeeting.data) {
    return <LoadingIndicator />;
  }

  const onToggle = (panel: string) => {
    const currentState = settings.accordionState || [];
    const newState = currentState.includes(panel)
      ? currentState.filter(item => item !== panel)
      : [...currentState, panel];

    setSetting('accordionState', newState);
  };

  const canManageDocuments = hasPermission(
    'manage-meeting-documents',
    'committee',
    loadMeeting.data.committee_id,
  );
  const canEditMeeting = hasPermission(
    'edit-meeting',
    'committee',
    loadMeeting.data.committee_id,
  );

  const isFutureMeeting = moment(loadMeeting.data.date_start).isAfter(moment());

  const canShareMeeting = hasPermission(
    'share-meeting',
    'committee',
    loadMeeting.data.committee_id,
  );

  const canDeleteMeeting = hasPermission(
    'delete-meeting',
    'committee',
    loadMeeting.data.committee_id,
  );

  const getDocumentDate = date => {
    return (
      <Typography variant="body2" color="textSecondary">
        Updated at: {formatParsedISO(date, "dd MMM yyyy 'at' HH:mm")}
      </Typography>
    );
  };

  return (
    <>
      {(canEditMeeting || canCreateMeetings) && (
        <MeetingFormDialog
          open={isOpen}
          onDelete={
            canDeleteMeeting
              ? meeting => {
                  confirm({
                    title: 'Delete meeting',
                  })
                    .then(() => {
                      dispatch(
                        actions.deleteMeetingRequest({
                          id: meeting.id,
                        }),
                      );
                    })
                    .catch(() => {});
                }
              : null
          }
          onChange={meeting => {
            if (+id !== +meeting.id) {
              navigate(
                `/organisation/${organisation.id}/meetings/${meeting.id}`,
              );
            } else {
              load();
            }
          }}
        />
      )}
      <Helmet title={loadMeeting.data.name}>
        <meta name="description" content="Committees within the organisation" />
      </Helmet>

      <Stack spacing={4}>
        <Box>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Stack direction={'row'} alignItems={'center'}>
                <Typography variant={'h3'}>{loadMeeting.data.name}</Typography>
                {canEditMeeting && (
                  <Button
                    variant="text"
                    startIcon={<Edit />}
                    onClick={() =>
                      open({ id, organisation_id: organisation.id })
                    }
                  >
                    Edit details
                  </Button>
                )}
              </Stack>
            </Grid>
            <Grid item xs={12} md={6}>
              {canShareMeeting && (
                <MeetingSettings
                  onSubmit={values =>
                    dispatch(
                      actions.updateMeetingSettingsRequest({
                        id: loadMeeting.data.id,
                        ...values,
                      }),
                    )
                  }
                  meeting={loadMeeting.data}
                  saving={updateMeetingSettings.loading}
                />
              )}
            </Grid>
          </Grid>
        </Box>

        <Paper
          variant={'outlined'}
          square
          sx={{
            px: 2,
            pt: 2,
            pb: 4,
            backgroundColor: 'primary.lightest',
          }}
        >
          <Box>
            <Grid container>
              <Grid item xs={12} md={6}>
                <Stack spacing={3}>
                  <Box>
                    <Stack spacing={4}>
                      <Box>
                        <Stack direction={'row'} alignItems={'center'}>
                          <CardMedia
                            component={'img'}
                            src={Pin}
                            sx={{ width: 20, height: 20 }}
                          />
                          <Typography color={'primary.main'}>
                            Essential info
                          </Typography>
                        </Stack>
                      </Box>
                      <TitledField
                        title={formatParsedISO(
                          loadMeeting.data.date_start,
                          'EEE dd MMM yyyy',
                        )}
                        width={3}
                        direction="row"
                        breakpoint="xl"
                      >
                        <Typography>
                          {formatParsedISO(
                            loadMeeting.data.date_start,
                            'HH:mm',
                          )}
                          -{formatParsedISO(loadMeeting.data.date_end, 'HH:mm')}
                        </Typography>
                      </TitledField>
                      <TitledField
                        title="Committee"
                        width={3}
                        direction="row"
                        breakpoint="xl"
                      >
                        <Typography>
                          {loadMeeting.data.committee.name}
                        </Typography>
                      </TitledField>
                      {loadMeeting.data.description && (
                        <TitledField
                          title="Description"
                          width={3}
                          direction="row"
                          breakpoint="xl"
                        >
                          <Typography>
                            {loadMeeting.data.description}
                          </Typography>
                        </TitledField>
                      )}
                      <TitledField
                        title="Location"
                        width={3}
                        direction="row"
                        breakpoint="xl"
                      >
                        <Box>
                          <Typography>
                            <LinkRenderer
                              location={
                                loadMeeting.data.location
                                  ? loadMeeting.data.location
                                  : 'Not described'
                              }
                            />
                          </Typography>
                        </Box>
                      </TitledField>
                      {isFutureMeeting && (
                        <TitledField
                          title="Add to calendar"
                          width={3}
                          direction="row"
                          breakpoint="xl"
                        >
                          <AddToCalendar
                            calendarView={'responsive'}
                            breakpoint="xl"
                            buttonProps={{
                              size: 'small',
                            }}
                            calendar_links={loadMeeting.data.calendar_links}
                          />
                        </TitledField>
                      )}
                    </Stack>
                  </Box>
                </Stack>
              </Grid>
              <Grid item xs={12} md={6}>
                <Stack>
                  <Paper
                    variant={'outlined'}
                    sx={{
                      p: 2,
                    }}
                    square
                  >
                    <TitledField title="Agenda" direction="column">
                      <Stack spacing={1}>
                        <MeetingDocument
                          title="Agenda"
                          loading={
                            saveDocument.loading || deleteDocument.loading
                          }
                          canManage={canManageDocuments}
                          initialDocument={loadMeeting.data?.agenda_document}
                          onSave={values =>
                            saveDocumentCB({
                              ...values,
                              category: 'agenda',
                            })
                          }
                          onDelete={() =>
                            deleteDocumentCB(
                              +loadMeeting.data?.agenda_document.id,
                            )
                          }
                        />
                        <Box>
                          {loadMeeting.data.agenda_document &&
                            getDocumentDate(
                              loadMeeting.data.agenda_document.updated_at,
                            )}
                        </Box>
                      </Stack>
                    </TitledField>
                  </Paper>

                  <Paper
                    square
                    variant="outlined"
                    sx={{ p: 2, height: '100%' }}
                  >
                    <TitledField
                      title="Minutes from previous meeting"
                      direction="column"
                    >
                      <Stack>
                        <Stack spacing={1}>
                          <Box>
                            <Stack direction={'row'} alignItems={'center'}>
                              <Box>
                                <Chip
                                  color={
                                    {
                                      draft: 'warning',
                                      pending: 'info',
                                      final: 'success',
                                    }[loadMeeting.data.minutes_status]
                                  }
                                  label={loadMeeting.data.minutes_status}
                                  sx={{
                                    textTransform: 'uppercase',
                                    color: 'common.white',
                                  }}
                                />
                              </Box>

                              <MeetingDocument
                                negativeStatement="No minutes attached"
                                loading={
                                  saveDocument.loading || deleteDocument.loading
                                }
                                canManage={canManageDocuments}
                                initialDocument={
                                  loadMeeting.data?.minutes_document
                                }
                                onSave={values =>
                                  saveDocumentCB({
                                    ...values,
                                    category: 'minutes',
                                  })
                                }
                                onDelete={() =>
                                  deleteDocumentCB(
                                    +loadMeeting.data?.minutes_document.id,
                                  )
                                }
                              />
                            </Stack>
                          </Box>
                          <Box>
                            {loadMeeting.data.minutes_document &&
                              getDocumentDate(
                                loadMeeting.data.minutes_document.updated_at,
                              )}
                          </Box>
                        </Stack>

                        {!!loadMeeting.data?.minutes_document &&
                          canEditMeeting && (
                            <TitledField title="Status">
                              <Select
                                fullWidth
                                value={loadMeeting.data.minutes_status}
                                onChange={e => {
                                  dispatch(
                                    actions.updateMeetingSettingsRequest({
                                      id: loadMeeting.data.id,
                                      minutes_status: e.target
                                        .value as MinutesStatus,
                                    }),
                                  );
                                }}
                              >
                                {[
                                  {
                                    value: 'draft',
                                    label: 'Draft',
                                    subtext: 'subject to agreement',
                                  },
                                  {
                                    value: 'pending',
                                    label: 'Pending',
                                    subtext:
                                      'minutes are awaiting approval by the chair',
                                  },
                                  {
                                    value: 'final',
                                    label: 'Final',
                                    subtext: `approved minutes agreed`,
                                  },
                                ].map(item => (
                                  <MenuItem key={item.value} value={item.value}>
                                    <Typography
                                      sx={{
                                        display: 'inline',
                                        whiteSpace: 'normal',
                                      }}
                                    >
                                      {item.label}{' '}
                                    </Typography>
                                    <Typography
                                      variant="body2"
                                      color="textSecondary"
                                      sx={{
                                        display: 'inline',
                                        whiteSpace: 'normal',
                                      }}
                                    >
                                      - {item.subtext}
                                    </Typography>
                                  </MenuItem>
                                ))}
                              </Select>
                            </TitledField>
                          )}
                      </Stack>
                    </TitledField>
                  </Paper>
                </Stack>
              </Grid>
            </Grid>
          </Box>
        </Paper>
        <Box>
          <Stack spacing={4}>
            <CustomAccordion
              open={settings.accordionState?.includes('working-papers')}
              onToggle={() => onToggle('working-papers')}
              title="Working papers"
              children={
                <>
                  <Stack spacing={2}>
                    <Box>
                      {!!loadMeeting.data.documents?.length ? (
                        <Stack>
                          <DataGridPro
                            autoHeight
                            getRowHeight={() => 80}
                            slots={{
                              columnHeaders: React.forwardRef((props, ref) => (
                                <Box ref={ref} />
                              )),
                            }}
                            columnVisibilityModel={{
                              actions: canManageDocuments,
                            }}
                            rowReordering={canManageDocuments}
                            onRowOrderChange={({
                              oldIndex,
                              targetIndex,
                              row,
                            }) => {
                              reorderDocumentsCB(oldIndex, targetIndex, row);
                            }}
                            hideFooter
                            columns={[
                              // { field: 'order_by' },
                              {
                                field: 'name',
                                headerName: 'Name',
                                minWidth: 200,
                                flex: 0.8,
                                sortable: false,
                                renderCell: params => (
                                  <MeetingDocument
                                    title="Working papers"
                                    loading={
                                      saveDocument.loading ||
                                      deleteDocument.loading ||
                                      reorderDocuments.loading
                                    }
                                    canManage={canManageDocuments}
                                    initialDocument={params.row}
                                    onSave={values =>
                                      saveDocumentCB({
                                        ...values,
                                        documentId: params.id,
                                        category: 'default',
                                      })
                                    }
                                    onDelete={() =>
                                      deleteDocumentCB(+params.row.id)
                                    }
                                  />
                                ),
                              },
                            ]}
                            rows={loadMeeting.data?.documents}
                          />
                          <Button
                            sx={{ alignSelf: 'flex-end' }}
                            variant="text"
                            startIcon={<Download />}
                            onClick={() => serveAllDocuments()}
                            disabled={false}
                          >
                            Download all documents
                          </Button>
                        </Stack>
                      ) : (
                        <>
                          <Box sx={{ p: 1 }}>
                            <Typography>No documents attached</Typography>
                          </Box>
                        </>
                      )}
                    </Box>
                    {canManageDocuments && (
                      <Box
                        sx={{
                          border: 2,
                          borderColor: 'grey.200',
                          borderStyle: 'dashed',
                          borderRadius: 2,
                          p: 2,
                        }}
                      >
                        <FileUploadComponent
                          multiple
                          onErrorEvent={error =>
                            snackbar.enqueueSnackbar(error, {
                              variant: 'error',
                            })
                          }
                          loading={saveDocument.loading}
                          handleFileChange={file => {
                            dispatch(
                              actions.saveDocumentRequest({
                                meetingId: +id,
                                file,
                                category: 'default',
                              }),
                            );
                          }}
                        >
                          <Stack spacing={1} alignItems={'center'}>
                            <Button startIcon={<RiAddCircleFill />}>
                              Select a Document to upload
                            </Button>
                            <Typography variant="body2">or</Typography>
                            <Button
                              variant="text"
                              sx={{
                                color: theme => theme.palette.common.black,
                                fontWeight: 'bold',
                              }}
                              startIcon={<RiDragDropFill />}
                            >
                              Drag & drop to upload
                            </Button>
                          </Stack>
                        </FileUploadComponent>
                      </Box>
                    )}
                  </Stack>
                </>
              }
            />

            {hasPermission(
              'view-action-register-items',
              'committee',
              loadMeeting.data.committee_id,
            ) && (
              <CustomAccordion
                title="Actions register"
                open={settings.accordionState?.includes('action-register')}
                onToggle={() => onToggle('action-register')}
                children={
                  <Stack spacing={4}>
                    <Box>
                      <ActionRegister
                        organisation_id={loadMeeting.data.organisation_id}
                        meeting_id={loadMeeting.data.id}
                        committee_id={loadMeeting.data.committee_id}
                        viewMode={'meeting'}
                        introText={
                          <>
                            <Typography>
                              Here are all actions for:{' '}
                              <Typography component={'span'}>
                                {loadMeeting.data.committee.name}
                              </Typography>
                            </Typography>
                          </>
                        }
                      />
                    </Box>
                  </Stack>
                }
              />
            )}
          </Stack>
        </Box>

        <CustomAccordion
          title="Declarations of interest"
          open={settings.accordionState?.includes('doi')}
          onToggle={() => onToggle('doi')}
          children={
            <>
              <Stack spacing={3}>
                <Typography>
                  Below you can view declarations of interest submitted by
                  members of the committee. These declarations provide
                  transparency regarding any personal, professional, or
                  financial relationships that could influence decisions in your
                  Organisation.
                </Typography>
                <ConflictsTable conflicts={loadMeeting.data.conflicts} />
              </Stack>
            </>
          }
        />

        {loadMeeting.data.published_at && (
          <CustomAccordion
            title="Meeting activity log"
            open={settings.accordionState?.includes('meeting-log')}
            onToggle={() => onToggle('meeting-log')}
            children={
              <Stack spacing={4} sx={{ mb: 2 }}>
                <>
                  {canEditMeeting && (
                    <MeetingLogOptions
                      initialLogUpdates={loadMeeting.data.log_updates}
                      initialNotifyOnUpdates={
                        loadMeeting.data.notify_on_updates
                      }
                      onCheck={values =>
                        dispatch(
                          actions.updateMeetingSettingsRequest({
                            id: loadMeeting.data.id,
                            ...values,
                          }),
                        )
                      }
                      saving={updateMeetingSettings.loading}
                    />
                  )}

                  {loadMeeting.data.updates.map(update => (
                    <Stack
                      spacing={2}
                      direction={'row'}
                      alignItems={'center'}
                      key={key(update)}
                    >
                      <Typography variant="body2">
                        <Box component={'span'} sx={{ fontWeight: 'bold' }}>
                          [{update.user_name || 'Unknown'}]{' '}
                        </Box>
                        {update.description || 'No description'}
                      </Typography>
                      <Chip
                        label={formatParsedISO(
                          update.created_at,
                          'dd/MM/yyyy HH:mm',
                        )}
                        sx={{
                          background: theme => theme.palette.grey[500],
                          color: 'common.white',
                        }}
                      />
                      {canEditMeeting && (
                        <IconButton
                          onClick={() => {
                            confirm({
                              title: 'Delete update',
                            })
                              .then(() => {
                                dispatch(
                                  actions.deleteMeetingUpdateRequest({
                                    meeting_id: +id,
                                    meeting_update_id: +update.id,
                                  }),
                                );
                              })
                              .catch(noop);
                          }}
                        >
                          <Delete />
                        </IconButton>
                      )}
                    </Stack>
                  ))}

                  {canEditMeeting && (
                    <Box>
                      <MeetingUpdateForm
                        showNotifyCheckbox={
                          !loadMeeting.data?.notify_on_updates
                        }
                        loading={createMeetingUpdate.loading}
                        onSubmit={values => {
                          dispatch(
                            actions.createMeetingUpdateRequest({
                              meetingId: +id,
                              ...values,
                            }),
                          );
                        }}
                      />
                    </Box>
                  )}
                </>
              </Stack>
            }
          />
        )}
      </Stack>
    </>
  );
}
