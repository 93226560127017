/**
 *
 * Committee Item
 *
 */
import * as React from 'react';
import { useCallback, useEffect } from 'react';
import {
  Box,
  Button,
  Container,
  Divider,
  Stack,
  Typography,
} from '@mui/material';
import { RiPencilLine, RiUploadCloudLine, RiUserAddLine } from 'react-icons/ri';
import { useCommitteesSlice } from './slice/hook';
import { useDispatch, useSelector } from 'react-redux';
import { selectCommittees } from './slice/selectors';
import { IOrganisation } from 'types/types';
import { useParams } from 'react-router';
import { useHasChanged } from 'utils/usePrevious';
import { LoadingIndicator } from 'app/components/LoadingIndicator';
import { DocumentsTable } from 'app/components/DocumentsTable';
import { MembersTable } from 'app/components/MembersTable';
import { useEffectOnce } from 'utils/useEffectOnce';
import {
  CommitteeMemberFormWidget,
  useCommitteeMemberFormWidget,
} from 'app/widgets/CommitteeMemberFormWidget';
import {
  CommitteeFormWidget,
  useCommitteeFormWidget,
} from 'app/widgets/CommitteeFormWidget';
import { DocumentFormWidget } from 'app/widgets/DocumentFormWidget';
import { Helmet } from 'react-helmet-async';
import { useHasPermission } from 'app/providers/AuthProvider/useHasPermission';
import { useConfirm } from 'utils/useConfirm';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { useDocumentFormWidget } from 'app/widgets/DocumentFormWidget/useDocumentFormWidget';
import { SectionHeader } from 'app/components/SectionHeader';

interface Props {
  organisation: IOrganisation;
}

export function Item(props: Props) {
  const { organisation } = props;
  const { actions } = useCommitteesSlice();
  const { loadCommittee, deleteCommittee } = useSelector(selectCommittees);
  const dispatch = useDispatch();

  const snackbar = useSnackbar();
  const navigate = useNavigate();
  const { hasPermission } = useHasPermission();
  const confirm = useConfirm();

  const { open, close } = useCommitteeFormWidget();
  const { open: cmOpen, remove: cmRemove } = useCommitteeMemberFormWidget();
  const { open: dOpen, remove: dRemove } = useDocumentFormWidget();
  const { id } = useParams();

  const loadCommitteeLoadingChanged = useHasChanged(loadCommittee.loading);
  const deleteCommitteeLoadingChanged = useHasChanged(deleteCommittee.loading);
  const idChanged = useHasChanged(id);

  useEffect(() => {
    if (loadCommitteeLoadingChanged && !loadCommittee.loading) {
      if (loadCommittee.error) {
        if (loadCommittee.error.code === 401) return;
        navigate('../', { replace: true });
      }
    }

    if (deleteCommitteeLoadingChanged && !deleteCommittee.loading) {
      if (deleteCommittee.error) {
        if (deleteCommittee.error.code === 401) return;
        snackbar.enqueueSnackbar(deleteCommittee.error.message, {
          variant: 'error',
        });
      } else {
        snackbar.enqueueSnackbar('Item deleted', {
          variant: 'success',
        });
        close();
        navigate('../', { replace: true });
      }
    }

    if (idChanged && id) {
      load();
    }
  });

  const load = useCallback(() => {
    dispatch(actions.loadCommitteeRequest({ id }));
  }, [organisation, id]);

  useEffectOnce(() => {
    load();
    return () => {
      dispatch(actions.loadCommitteeInit());
    };
  });

  if (!loadCommittee.data) {
    return <LoadingIndicator />;
  }

  const canManageDocuments = hasPermission(
    'manage-committee-documents',
    'committee',
    loadCommittee.data.id,
  );

  return (
    <>
      <Helmet title={loadCommittee.data.name}>
        <meta name="description" content="Committees within the organisation" />
      </Helmet>
      <Box>
        <Stack direction={'row'} alignItems={'center'}>
          <Typography variant={'h3'}>{loadCommittee.data.name}</Typography>
          {hasPermission(
            'manage-committees',
            'organisation',
            loadCommittee.data.organisation_id,
          ) && (
            <>
              <Button
                startIcon={<RiPencilLine />}
                variant="text"
                onClick={() =>
                  open({
                    organisation_id: organisation.id,
                    id: loadCommittee.data.id,
                  })
                }
              >
                Edit committee
              </Button>
              <CommitteeFormWidget
                onDelete={committee =>
                  confirm({
                    title: 'Delete committee',
                  })
                    .then(() => {
                      dispatch(
                        actions.deleteCommitteeRequest({ id: committee.id }),
                      );
                    })
                    .catch(() => {})
                }
                onChange={() => load()}
              />
            </>
          )}
        </Stack>
      </Box>
      <Divider />

      <Box>
        <Stack
          direction={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <Typography variant={'h3'}>Committee members</Typography>
          {hasPermission(
            'manage-committee-members',
            'committee',
            loadCommittee.data.id,
          ) && (
            <>
              <Button
                variant={'outlined'}
                startIcon={<RiUserAddLine />}
                onClick={() =>
                  cmOpen({
                    organisation_id: organisation.id,
                    committee_id: loadCommittee.data.id,
                  })
                }
                disabled={loadCommittee.data.is_full}
              >
                Add committee member
              </Button>
              <CommitteeMemberFormWidget onChange={() => load()} />
            </>
          )}
        </Stack>
      </Box>
      <MembersTable
        members={loadCommittee.data.members}
        editable={hasPermission(
          'manage-committee-members',
          'committee',
          loadCommittee.data.id,
        )}
        onEdit={id =>
          cmOpen({
            id,
            organisation_id: organisation.id,
            committee_id: loadCommittee.data.id,
          })
        }
        onRemove={id =>
          confirm({
            title: 'Remove member from committee',
          })
            .then(() => cmRemove({ id }))
            .catch(() => {})
        }
      />

      {hasPermission(
        'view-committee-documents',
        'committee',
        loadCommittee.data.id,
      ) && (
        <>
          <Divider />
          <Box>
            <Stack
              direction={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}
            >
              <Typography variant={'h3'}>Documents</Typography>
              {canManageDocuments && (
                <>
                  <Button
                    variant={'outlined'}
                    startIcon={<RiUploadCloudLine />}
                    onClick={() =>
                      dOpen({
                        parent_entity_type: 'committee',
                        parent_entity_id: loadCommittee.data.id,
                      })
                    }
                  >
                    Upload
                  </Button>
                  <DocumentFormWidget onChange={load} categories={[]} />
                </>
              )}
            </Stack>
          </Box>
          <DocumentsTable
            meta={{
              current_page: 0,
              from: 0,
              last_page: 0,
              per_page: loadCommittee.data.documents.length,
              to: loadCommittee.data.documents.length,
              total: loadCommittee.data.documents.length,
            }}
            documents={loadCommittee.data.documents}
            editable={canManageDocuments}
            downloadable={hasPermission(
              'download-committee-documents',
              'committee',
              loadCommittee.data.id,
            )}
            onEdit={id => {
              dOpen({
                id,
              });
            }}
            onRemove={id =>
              confirm({
                title: 'Remove document',
              })
                .then(() => dRemove({ id }))
                .catch(() => {})
            }
          />
        </>
      )}
    </>
  );
}
