/**
 *
 * DocumentForm
 *
 */
import React from 'react';
import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  Stack,
  TextField as MuiTextField,
  Typography,
  FormControlLabel,
  Switch,
} from '@mui/material';
import { RiCheckFill, RiCloseLine } from 'react-icons/ri';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterMoment } from '@mui/x-date-pickers-pro/AdapterMoment';
import { Field, Form, Formik } from 'formik';
import { TextField, Autocomplete, CheckboxWithLabel } from 'formik-mui';
import { IDocument } from 'types/types';
import { UploadApiCall } from 'types/ApiCall';
import * as Yup from 'yup';
import { ResetAndSaveButtons } from 'app/components/ResetAndSaveButtons';
import { RequiredFieldIndicator } from 'app/components/RequiredFieldIndicator';
import FileUpload from 'app/components/FileUpload';
import { DateFieldWrapper } from 'app/components/DateFieldWrapper';
import { RadioButton } from 'app/components/RadioButton';
import { AutoUpdateName } from 'app/components/AutoUpdateName';
import { TitledField } from 'app/components/TitledField';
import { DocumentUploadAndTitleContent } from 'app/components/DocumentUploadAndTitleContent';

interface Props {
  onClose: () => void;
  document: IDocument;
  onSave: (payload: Partial<IDocument>) => void;
  saveDocument: UploadApiCall;
  saving: boolean;
  categories?: { label: string; value: string }[];
  defaultCategory?: string;
}

type FormType = Partial<IDocument> & {
  add_review_date?: boolean;
  add_expiry_date?: boolean;
};

function createSchema(shouldValidate) {
  return Yup.object({
    file: Yup.mixed()
      .test(
        'is-object-or-string',
        'A document is required',
        value =>
          value &&
          (typeof value === 'string' ||
            (typeof value === 'object' && value !== null)),
      )
      .required('A document is required'),
    name: Yup.string().required('This field is required'),
    description: Yup.string(),
    categories: shouldValidate
      ? Yup.array().of(Yup.string()).min(1, 'At least one category')
      : Yup.array().of(Yup.string()).notRequired(),
  });
}

export function DocumentForm(props: Props) {
  const {
    document,
    onSave,
    saveDocument,
    onClose,
    saving,
    categories = [],
    defaultCategory = 'reference',
  } = props;

  const shouldValidateCategories = categories.length > 0;
  const schema = createSchema(shouldValidateCategories);

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Formik<FormType>
        validationSchema={schema}
        initialValues={
          document
            ? {
                ...document,
                add_review_date: !!document.review_at,
                add_expiry_date: !!document.expires_at,
              }
            : ({
                id: undefined,
                name: '',
                description: '',
                categories: defaultCategory ? [defaultCategory] : [],
                file: undefined,
                expires_at: undefined,
                review_at: undefined,
                add_review_date: false,
                add_expiry_date: false,
              } as FormType)
        }
        enableReinitialize
        validateOnChange
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          onSave(values);
        }}
      >
        {formik => {
          const {
            touched,
            setFieldValue,
            isSubmitting,
            errors,
            dirty,
            values,
            isValid,
            submitCount,
          } = formik;

          return (
            <Form>
              <DialogTitle>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Box sx={{ mr: 'auto' }}>
                    {document ? 'Manage' : 'Create'} document
                  </Box>

                  <IconButton onClick={onClose} edge={'end'} size={'large'}>
                    <RiCloseLine />
                  </IconButton>
                </Stack>
              </DialogTitle>
              <DialogContent>
                <Box>
                  <Stack spacing={3}>
                    <DocumentUploadAndTitleContent
                      errorFile={errors.file}
                      touchedFile={touched.file}
                    />
                    {(!!document &&
                      document?.parent_entity_type !== 'organisation') ||
                    categories.length === 0 ? null : (
                      <Box>
                        <TitledField title="Category *">
                          <FormControl sx={{ width: '100%' }}>
                            <Field
                              id="categories"
                              component={Autocomplete}
                              name="categories"
                              label="Categories"
                              fullWidth
                              multiple
                              getOptionLabel={option =>
                                categories.find(c => c.value === option)
                                  ?.label || option
                              }
                              renderInput={params => (
                                <MuiTextField
                                  {...params}
                                  InputProps={{
                                    ...params.InputProps,
                                  }}
                                  label="Categories"
                                  error={
                                    touched.categories && !!errors.categories
                                  }
                                />
                              )}
                              onChange={(e, val, reason) => {
                                setFieldValue('categories', val);
                              }}
                              options={categories.map(c => c.value)}
                            />{' '}
                            {(!!submitCount || touched.categories) &&
                              errors.categories && (
                                <FormHelperText error>
                                  {errors.categories}
                                </FormHelperText>
                              )}
                          </FormControl>
                        </TitledField>
                      </Box>
                    )}
                    <TitledField title="Document validity">
                      <Box>
                        <Grid container spacing={3}>
                          <Grid item xs={12} md={6}>
                            <RadioButton
                              onChange={e => {
                                setFieldValue(
                                  'add_expiry_date',
                                  e.target.checked,
                                );
                                if (
                                  document?.expires_at ||
                                  document?.review_at
                                ) {
                                  setFieldValue('expires_at', undefined);
                                  setFieldValue('review_at', undefined);
                                }
                              }}
                              checked={!values.add_expiry_date}
                              label="This document is evergreen"
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <RadioButton
                              onChange={e =>
                                setFieldValue(
                                  'add_expiry_date',
                                  !e.target.checked,
                                )
                              }
                              checked={values.add_expiry_date}
                              label="This document has an expiry date"
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    </TitledField>
                    {values.add_expiry_date && (
                      <Box>
                        <Stack>
                          <TitledField title="Expiry date">
                            <DateFieldWrapper
                              label={'DD/MM/YYYY'}
                              name="expires_at"
                              format={'DD/MM/YYYY'}
                              sx={{ width: '100%' }}
                            />
                          </TitledField>

                          <Field
                            type={'checkbox'}
                            component={CheckboxWithLabel}
                            name={'add_review_date'}
                            edge="start"
                            sx={{ py: 1 }}
                            onChange={e => {
                              setFieldValue(
                                'add_review_date',
                                e.target.checked,
                              );
                              if (!e.target.checked) {
                                setFieldValue('review_at', undefined);
                              }
                            }}
                            Label={{
                              label: `${
                                !values?.add_review_date ? 'Add' : 'Remove'
                              } review date`,
                            }}
                          />
                          {values?.add_review_date && (
                            <TitledField title="Review date">
                              <DateFieldWrapper
                                sx={{ width: '100%' }}
                                label={'DD/MM/YYYY'}
                                name="review_at"
                                format={'DD/MM/YYYY'}
                              />
                            </TitledField>
                          )}
                        </Stack>
                      </Box>
                    )}
                  </Stack>
                </Box>
              </DialogContent>
              <DialogActions>
                <AutoUpdateName />
                <Stack direction={'row'} justifyContent={'flex-end'}>
                  <ResetAndSaveButtons
                    saving={saving}
                    saveText="Save & Publish"
                  />
                </Stack>
              </DialogActions>
            </Form>
          );
        }}
      </Formik>
    </LocalizationProvider>
  );
}
