import { Box, Grid, Typography, Button } from '@mui/material';

import { useFormikContext } from 'formik';
import React from 'react';
import { ActionRegisterCommittee } from 'app/widgets/ActionRegister/slice/types';
import { ActionRegisterFormType } from '../../ActionRegisterDetailsForm';
import { AssignOwnerField } from '../../fields/AssignOwnerField';

interface Props {
  previous: () => void;
  next: () => void;
  committees: ActionRegisterCommittee[];
  committeePath?: string;
}

export function AssignOwner(props: Props) {
  const { next, previous, committees, committeePath } = props;

  const { isValid, values } = useFormikContext<ActionRegisterFormType>();

  return (
    <>
      <Typography variant="h3">
        Assign an owner <Typography component={'span'}>(optional)</Typography>{' '}
      </Typography>
      <AssignOwnerField
        committeePath={`${committeePath}/${values.committee_id}`}
        committees={committees}
      />

      <Box pt={2}>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <Button fullWidth variant="outlined" onClick={previous}>
              Previous
            </Button>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button fullWidth onClick={next} disabled={!isValid}>
              Next
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
