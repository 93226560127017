import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useEffectOnce } from 'utils/useEffectOnce';
import key from 'weak-key';
import { useConfirm } from 'utils/useConfirm';
import { noop } from 'utils/noop';
import { Link } from 'react-router-dom';

interface Props {
  returnPath: string;
  init: () => void;
  request: () => void;
  reset: () => void;
  loading: boolean;
  codes: string[];
  cleared: boolean;
}

export function GetBackupCodes(props: Props) {
  const { returnPath, request, reset, init, codes } = props;

  const confirm = useConfirm();

  useEffectOnce(() => {
    init();
    return () => {
      init();
    };
  });

  const generateBackupCode = () =>
    confirm({ title: 'Generate new backup codes' }).then(request).catch(noop);
  const clearAll = () =>
    confirm({ title: 'Invalidate and clear all backup codes' })
      .then(reset)
      .catch(noop);

  return (
    <Box>
      <Stack spacing={3} alignItems={'center'} textAlign={'center'}>
        <Typography variant="h3" color={'primary.main'}>
          Get backup codes
        </Typography>
        <Typography variant="body1">
          Backup codes let you authenticate when you don’t have access to your
          2-factor authentication device or method.
        </Typography>
        <Box>
          <Button
            variant="outlined"
            onClick={generateBackupCode}
            sx={{ mb: 3 }}
          >
            Generate new backup codes now
          </Button>

          {!!codes.length && (
            <Grid container rowSpacing={2} columnSpacing={2}>
              {codes.map(code => (
                <Grid item xs={4} key={key({ code, type: 'number' })}>
                  <Typography variant="body1" fontWeight={900}>
                    {code}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          )}
        </Box>

        <Typography variant="body1">
          You can only use each code once. If you run out of available codes,
          you can generate new backup codes using the button above. Generating
          new codes will invalidate all old codes.
        </Typography>
        <Button component={Link} to={returnPath}>
          <Typography variant="body1">
            Return to your account settings
          </Typography>
        </Button>
        <Button variant="text" onClick={clearAll}>
          Invalidate and clear all backup codes
        </Button>
      </Stack>
    </Box>
  );
}
