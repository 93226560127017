/**
 *
 * InvitationForm
 *
 */
import * as React from 'react';
import { useCallback, useEffect } from 'react';
import { InvitationForm } from './InvitationForm';
import { useInvitationFormWidgetSlice } from './slice';
import { useDispatch, useSelector } from 'react-redux';
import { selectInvitationFormWidget } from './slice/selectors';
import { LoadingIndicator } from 'app/components/LoadingIndicator';
import { Dialog, useMediaQuery, useTheme } from '@mui/material';
import { IInvitation } from 'types/types';
import { useSnackbar } from 'notistack';
import { useHasChanged } from 'utils/usePrevious';
import { useEffectOnce } from 'utils/useEffectOnce';
import { noop } from 'utils/noop';

interface Props {
  onChange?: (payload: Partial<IInvitation>) => void;
  onDelete?: (payload: IInvitation) => void;
}

export function InvitationFormWidget(props: Props) {
  const { onChange = noop, onDelete } = props;
  const { actions } = useInvitationFormWidgetSlice();
  const dispatch = useDispatch();
  const {
    form: { open, loading, id, roles, committees, organisation_id },
    loadInvitation,
    saveInvitation,
  } = useSelector(selectInvitationFormWidget);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const load = useCallback(() => {
    if (id) {
      dispatch(actions.loadInvitationRequest({ id }));
    }
  }, [id]);

  const snackbar = useSnackbar();
  const savingChanged = useHasChanged(saveInvitation.loading);

  useEffect(() => {
    if (savingChanged && !saveInvitation.loading) {
      if (!saveInvitation.loading && saveInvitation.error) {
        let msg = 'Error saving invitation';
        if (saveInvitation.error.code === 406) {
          msg = saveInvitation.error.message;
        }
        snackbar.enqueueSnackbar(msg, { variant: 'error' });
      } else {
        onChange(saveInvitation.data);
        snackbar.enqueueSnackbar('Saved.', { variant: 'success' });
        close();
      }
    }
  });

  useEffect(() => {
    if (open && id) load();
  }, [open]);

  useEffect(
    () => () => {
      dispatch(actions.close());
    },
    [dispatch],
  );

  useEffectOnce(() => {
    snackbar.closeSnackbar();
    return () => {
      snackbar.closeSnackbar();
    };
  });

  const close = () => dispatch(actions.close());

  return (
    <Dialog
      open={open}
      maxWidth={'md'}
      fullWidth
      fullScreen={fullScreen}
      scroll={'body'}
      onClose={close}
    >
      {loading || loadInvitation.loading ? (
        <LoadingIndicator />
      ) : (
        <>
          <InvitationForm
            onDelete={onDelete}
            onClose={close}
            invitation={loadInvitation.data}
            roles={roles}
            committees={committees}
            onSave={payload => {
              console.log(payload);
              dispatch(
                actions.saveInvitationRequest({ organisation_id, ...payload }),
              );
            }}
            saving={saveInvitation.loading}
          />
        </>
      )}
    </Dialog>
  );
}

export function useInvitationFormWidget() {
  const { actions } = useInvitationFormWidgetSlice();
  const dispatch = useDispatch();
  const invitationForm = useSelector(selectInvitationFormWidget);

  const open = payload => {
    dispatch(actions.openRequest(payload));
  };
  const close = () => dispatch(actions.close());

  return { open, close, isOpen: invitationForm.form.open };
}
