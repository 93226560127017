import { Edit } from '@mui/icons-material';
import {
  Avatar,
  Box,
  Button,
  Paper,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { IActionRegisterItem } from 'types/types';
import { getInitials } from 'utils/initials';
import { getDateWIthFallbackString } from '../../../../utils/getDateWIthFallbackString';
import { ActionStatusChip } from 'app/components/ActionStatusChip';

interface Props {
  actions?: IActionRegisterItem[];
  manageableCommitteeIds?: number[];
  context?: 'default' | 'meeting' | 'dashboard';
  saving?: boolean;
  onEdit: (id: number, mode: 'details' | 'assignees' | 'status') => void;
  onDelete?: (id: number) => void;
  showNotes: boolean;
}

export function ActionRegisterTable(props: Props) {
  const {
    actions,
    manageableCommitteeIds = [],
    context,
    showNotes = false,
    onEdit,
    saving = false,
  } = props;

  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <Box>
      <DataGridPro
        loading={saving}
        slots={{
          noResultsOverlay: () => (
            <Stack height="100%" alignItems="center" justifyContent="center">
              No actions
            </Stack>
          ),
          noRowsOverlay: () => (
            <Paper
              square
              variant="outlined"
              sx={{ height: '100%', width: '100%' }}
            >
              <Stack height="100%" alignItems="center" justifyContent="center">
                <Typography variant="body2">No actions</Typography>
              </Stack>
            </Paper>
          ),
        }}
        columns={[
          {
            field: 'reference',
            headerName: 'Ref No',
            minWidth: 100,
            flex: 0.1,
          },
          {
            field: 'description',
            minWidth: 150,
            headerName: 'Action',
            flex: 1,
            renderCell: params => {
              return manageableCommitteeIds.find(
                id => id === params.row.committee_id,
              ) ? (
                <Button
                  size="small"
                  onClick={() => onEdit(+params.id, 'details')}
                  variant="text"
                  endIcon={<Edit />}
                  sx={{ pl: 0, textAlign: 'start' }}
                >
                  <Typography variant={'body2'}>{params.value}</Typography>
                </Button>
              ) : (
                <Typography variant={'body2'}>{params.value}</Typography>
              );
            },
          },
          {
            field: 'committee_name',
            headerName: 'Committee',
            minWidth: 150,
            flex: 0.2,
            renderCell: params => {
              const committeePath =
                context === 'default' || context === 'meeting'
                  ? `../../committees/${params.row.committee_id}`
                  : `committees/${params.row.committee_id}`;

              return (
                <Tooltip title={`${params.row.committee_name} page`}>
                  <Button
                    size="small"
                    component={Link}
                    to={committeePath}
                    variant="text"
                    sx={{ pl: 0 }}
                  >
                    <Typography variant={'body2'}>
                      {params.row.committee_name}
                    </Typography>
                  </Button>
                </Tooltip>
              );
            },
          },

          {
            field: 'assignedTo',
            headerName: 'Assigned to',
            minWidth: 150,
            renderCell: params => {
              const avatarColor = index => {
                const colors = [
                  'secondary.light',
                  'success.light',
                  'primary.light',
                  'warning.light',
                ];
                return colors[index % 4];
              };

              const assignees = params.row.members.map(
                member => `${member.first_name} ${member.last_name}`,
              );
              const editable = manageableCommitteeIds.find(
                id => id === params.row.committee_id,
              );
              const getSurnameInitial = (name: string) => {
                const nameParts = name.split(' ');
                if (nameParts.length >= 2) {
                  return `${nameParts[0]} ${
                    nameParts[nameParts.length - 1][0]
                  }`;
                }
                return name;
              };

              const renderAssignees =
                assignees.length <= 1 ? (
                  <Typography variant={'body2'}>
                    {assignees.length === 1
                      ? getSurnameInitial(assignees[0])
                      : 'No assignees'}
                  </Typography>
                ) : (
                  <Box sx={{ overflow: 'auto' }}>
                    <Stack direction={'row'} spacing={-1}>
                      {assignees.map((val, index) => (
                        <React.Fragment key={index}>
                          <Tooltip title={val}>
                            <Avatar
                              sx={{
                                backgroundColor: avatarColor(index),
                                color: 'black',
                              }}
                            >
                              {getInitials(val)}
                            </Avatar>
                          </Tooltip>
                        </React.Fragment>
                      ))}
                    </Stack>
                  </Box>
                );

              return (
                <>
                  {editable ? (
                    <Button
                      variant="text"
                      sx={{ px: 0 }}
                      endIcon={<Edit />}
                      onClick={() => {
                        onEdit(+params.id, 'assignees');
                      }}
                    >
                      {renderAssignees}
                    </Button>
                  ) : (
                    renderAssignees
                  )}
                </>
              );
            },
          },
          {
            field: 'created_at',
            type: 'date',
            headerName: 'Opened',
            minWidth: 100,
            flex: 0.1,
            renderCell: params => {
              return (
                <Typography variant={'body2'}>
                  {params.value.toLocaleDateString()}
                </Typography>
              );
            },
            valueGetter: ({ row }) => {
              const dateValue = getDateWIthFallbackString(row.created_at);
              return new Date(dateValue);
            },
          },
          {
            field: 'due_at',
            type: 'date',
            headerName: 'Due',
            minWidth: 100,
            flex: 0.1,
            renderCell: params => {
              return (
                <Typography variant={'body2'}>
                  {params.value.toLocaleDateString()}
                </Typography>
              );
            },
            valueGetter: ({ row }) => {
              const dateValue = getDateWIthFallbackString(row.due_at);
              return new Date(dateValue);
            },
          },
          {
            field: 'status',
            headerName: 'Status',
            minWidth: 120,
            flex: 0.1,
            renderCell: params => {
              const status = params.value;
              const editable = manageableCommitteeIds.find(
                id => id === params.row.committee_id,
              );

              const statuses = [
                { value: 'open', label: 'Open' },
                { value: 'closed', label: 'Closed' },
              ];

              const currentStatus = statuses.find(s => s.value === status);
              const statusLabel = currentStatus ? currentStatus.label : status;
              const statusColor = status === 'open' ? 'secondary' : 'success';

              return (
                <>
                  {editable ? (
                    <Button
                      onClick={() => onEdit(+params.id, 'status')}
                      endIcon={<Edit />}
                      sx={{
                        backgroundColor: `${statusColor}.light`,
                        color: `${statusColor}.main`,
                        '&:hover, &.isActive': {
                          backgroundColor: `${statusColor}.light`,
                          color: `${statusColor}.main`,
                        },
                      }}
                    >
                      <Typography variant={'body2'}>{statusLabel}</Typography>
                    </Button>
                  ) : (
                    <Stack width={'100%'}>
                      <ActionStatusChip status={status} title={statusLabel} />
                    </Stack>
                  )}
                </>
              );
            },
          },
          {
            field: 'notes',
            flex: 1,
            headerName: 'Closing Notes',
            valueGetter: ({ row }) => row.notes || '',
            renderCell: params => (
              <>
                {params.row.status === 'closed' ? (
                  <Typography variant="body2">
                    {params.value ? params.value : 'No notes added'}
                  </Typography>
                ) : (
                  <Typography variant="body2">N/A</Typography>
                )}
              </>
            ),
          },
          {
            field: 'closed_at',
            type: 'date',
            headerName: 'Closed Date',
            minWidth: 100,
            flex: 0.1,
            renderCell: params => (
              <>
                {params.row.status === 'closed' ? (
                  <Typography variant="body2">
                    {params.row.closed_at
                      ? params.value.toLocaleDateString()
                      : 'No added date'}
                  </Typography>
                ) : (
                  <Typography variant="body2">N/A</Typography>
                )}
              </>
            ),
            valueGetter: ({ row }) => {
              if (!row.closed_at) return null;
              const dateValue = getDateWIthFallbackString(row.closed_at);
              return new Date(dateValue);
            },
          },
        ]}
        columnVisibilityModel={{
          committee_name: context !== 'meeting',
          created_at: isXs,
          notes: showNotes,
          closed_at: showNotes,
        }}
        rows={[...actions]}
        autoHeight
        disableColumnMenu
        hideFooter
        sx={{
          '& .MuiDataGrid-columnHeader': {
            backgroundColor: 'primary.lightest',
          },
        }}
      />
    </Box>
  );
}
