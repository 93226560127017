import {
  Box,
  Button,
  Grid,
  Stack,
  TextField as MuiTextField,
  Typography,
} from '@mui/material';
import { Autocomplete, TextField } from 'formik-mui';
import { Field, useFormikContext } from 'formik';
import React from 'react';
import { ActionRegisterCommittee } from '../../slice/types';
import { DateFieldWrapper } from 'app/components/DateFieldWrapper';

interface Props {
  committees: ActionRegisterCommittee[];
}

export function ActionDetailsFields(props: Props) {
  const { committees } = props;
  const { setFieldValue, touched, errors } = useFormikContext();

  return (
    <Stack>
      <Box>
        <Stack>
          <Stack spacing={1}>
            <Typography variant="h5">Action</Typography>
            <Typography variant="body2">
              This description should explain how the register will be used to
              track, manage, and report on actions across the organisation or
              committee.
            </Typography>
          </Stack>

          <Field
            name="description"
            component={TextField}
            label="Enter an action"
          />
        </Stack>
      </Box>
      <Box>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <Box>
              <Stack>
                <Typography variant="h5">Committee</Typography>
                <Field
                  component={Autocomplete}
                  name="committee_id"
                  fullWidth
                  getOptionLabel={option =>
                    committees.find(c => c.id === option)?.name || option
                  }
                  renderInput={params => (
                    <MuiTextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                      }}
                      label="Select the committee"
                      //   error={touched.committee_id && !!errors.committee_id}
                    />
                  )}
                  options={committees.map(c => c.id)}
                  onChange={(e, value) => {
                    setFieldValue('member_ids', []);
                    setFieldValue('committee_id', value);
                  }}
                />{' '}
              </Stack>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box>
              <Stack>
                <Typography variant="h5">Due date</Typography>
                <DateFieldWrapper name="due_at" format={'DD/MM/YYYY'} />
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Stack>
  );
}
