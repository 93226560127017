import React, { useCallback, useEffect, useMemo } from 'react';
import { ActionRegisterForm } from './forms/ActionRegisterForm';
import { ActionRegisterTable } from './ActionRegisterTable';
import { useEffectOnChange } from '../../../utils/useEffectOnChange';
import { useDispatch, useSelector } from 'react-redux';
import { useActionRegisterSlice } from './slice/hook';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  FormControlLabel,
  Grid,
  MenuItem,
  Stack,
  Switch,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { RiAddCircleFill, RiDownload2Line } from 'react-icons/ri';
import { selectActionRegister } from './slice/selectors';
import useHandleApiResponse from '../../../utils/useHandleApiResponse';
import key from 'weak-key';
import { SelectControl } from 'app/components/SelectControl';
import { getApiUrl, serveFile } from '../../../utils/request';
import { now } from 'moment/moment';
import { noop } from 'lodash';
import { useHasPermission } from 'app/providers/AuthProvider/useHasPermission';
import { ActionRegisterStatusForm } from './forms/ActionRegisterStatusForm';
import { ActionRegisterDetailsForm } from './forms/ActionRegisterDetailsForm';
import { ActionRegisterAssigneesForm } from './forms/ActionRegisterAssigneesForm';

interface Props {
  introText?: React.ReactNode;
  organisation_id: number | string;
  committee_id?: number | string;
  meeting_id?: number | string;
  viewMode?: 'dashboard' | 'meeting' | 'default';
}

export function ActionRegister(props: Props) {
  const {
    introText,
    organisation_id,
    committee_id,
    meeting_id,
    viewMode = 'default',
  } = props;

  const initialListFilterState: {
    show_open: boolean;
    show_closed: boolean;
    assigned_to_me: boolean;
    committee_id?: number | string;
    show_notes: boolean;
  } = {
    show_open: true,
    show_closed: false,
    assigned_to_me: false,
    show_notes: false,
    committee_id: committee_id || '',
  };

  const {
    loadActionRegisterOptions,
    loadActionRegisterItem,
    saveActionRegisterItem,
    loadActionRegisterItemList,
  } = useSelector(selectActionRegister);

  const [listFilter, setListFilter] = React.useState(initialListFilterState);

  const handleFilterChange = event => {
    const value = event.target.value;
    switch (value) {
      case 'open':
        setListFilter({
          ...listFilter,
          show_open: true,
          show_closed: false,
        });
        break;
      case 'closed':
        setListFilter({
          ...listFilter,
          show_open: false,
          show_closed: true,
        });
        break;
      default:
        setListFilter({
          ...listFilter,
          show_open: true,
          show_closed: true,
        });
    }
  };

  const committeePath = `/organisation/${organisation_id}/committees`;

  const save = () => {
    serveFile(
      getApiUrl(
        `exports/action-register?organisation_id=${organisation_id}&committee_id=${
          committee_id ?? ''
        }&nonce=${now()}`,
      ),
    )
      .then(noop)
      .catch(noop);
  };

  const getFilterValue = filter => {
    if (filter.show_open && !filter.show_closed) return 'open';
    if (!filter.show_open && filter.show_closed) return 'closed';
    return 'all';
  };

  const [formOpen, setFormOpen] = React.useState(false);
  const [editMode, setEditMode] = React.useState<
    'details' | 'assignees' | 'status'
  >('details');
  const [editStatusFormOpen, setEditStatusFormOpen] = React.useState(false);
  const [editDetailsFormOpen, setEditDetailsFormOpen] = React.useState(false);
  const [editAssigneesFormOpen, setEditAssigneesFormOpen] =
    React.useState(false);

  const { actions } = useActionRegisterSlice();

  const dispatch = useDispatch();

  const load = useCallback(() => {
    dispatch(
      actions.loadActionRegisterItemListRequest({
        organisation_id: organisation_id,
        committee_id: committee_id,
        meeting_id: meeting_id,
        ...listFilter,
      }),
    );
  }, [organisation_id, committee_id, meeting_id, listFilter]);

  useEffect(() => {
    dispatch(
      actions.loadActionRegisterOptionsRequest({ id: Number(organisation_id) }),
    );
  }, [organisation_id]);

  useEffect(() => {
    load();
  }, [load]);

  useEffectOnChange(
    () => {
      dispatch(actions.loadActionRegisterItemInit());
    },
    formOpen,
    false,
  );

  useHandleApiResponse(loadActionRegisterItem, null, {
    onSuccess: () => {
      switch (editMode) {
        case 'status':
          return setEditStatusFormOpen(true);
        case 'details':
          return setEditDetailsFormOpen(true);
        case 'assignees':
          return setEditAssigneesFormOpen(true);
        default:
          return setFormOpen(true);
      }
    },
  });

  useHandleApiResponse(saveActionRegisterItem, 'Item saved', {
    onSuccess: () => {
      setFormOpen(false);
      setEditStatusFormOpen(false);
      setEditDetailsFormOpen(false);
      setEditAssigneesFormOpen(false);
      load();
    },
    errorMessage: 'Failed to save item',
  });

  const canAddAction = useMemo(() => {
    return (
      !!loadActionRegisterOptions.data?.managed_committees.length &&
      (!committee_id ||
        loadActionRegisterOptions.data?.managed_committees.find(
          c => c.id === committee_id,
        ))
    );
  }, [loadActionRegisterOptions.data, committee_id]);

  const { hasPermission } = useHasPermission();

  const canExport = hasPermission(
    'export-action-register',
    'organisation',
    organisation_id,
  );

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const calculateGridWidth = useMemo(() => {
    if (canExport && canAddAction) {
      return 4;
    } else if ((canExport && !canAddAction) || (!canExport && canAddAction)) {
      return 6;
    } else {
      return 12;
    }
  }, [canExport, canAddAction]);

  return (
    <Stack spacing={2}>
      <Box>
        <Grid container>
          <Grid item xs={12} md={viewMode === 'default' ? 8 : 12}>
            {viewMode === 'default' ? (
              <>
                You can view all actions and filter them as needed. Only the
                organisation admin can create, edit, and update actions for all
                committees, while a committee admin can manage actions but only
                for their specific committee.
              </>
            ) : (
              <>{introText}</>
            )}
          </Grid>
          {canAddAction && viewMode === 'default' && (
            <Grid item xs={12} md={4}>
              <Stack
                direction={'row'}
                spacing={2}
                justifyContent={{ xs: 'start', md: 'end' }}
              >
                {canExport && (
                  <Button
                    variant="outlined"
                    startIcon={<RiDownload2Line />}
                    disabled={!loadActionRegisterItemList.data.length}
                    onClick={() => save()}
                  >
                    Export
                  </Button>
                )}
                {canAddAction && (
                  <Button
                    startIcon={<RiAddCircleFill />}
                    onClick={() => setFormOpen(true)}
                  >
                    Add action
                  </Button>
                )}
              </Stack>
            </Grid>
          )}
        </Grid>
      </Box>

      <Box>
        <Stack
          direction={{ xs: 'column', lg: 'row' }}
          justifyContent={'space-between'}
          alignItems={{ xs: 'start', lg: 'center' }}
        >
          <Box>
            <Stack direction={{ xs: 'column', lg: 'row' }}>
              <SelectControl
                sx={{ minWidth: 200 }}
                onChange={handleFilterChange}
                value={getFilterValue(listFilter)}
              >
                <MenuItem value="all">All actions</MenuItem>
                <MenuItem value="open">Show in progress</MenuItem>
                <MenuItem value="closed">Show closed</MenuItem>
              </SelectControl>

              {!committee_id && (
                <Box>
                  <SelectControl
                    sx={{ minWidth: 200 }}
                    onChange={e =>
                      setListFilter({
                        ...listFilter,
                        committee_id: +e.target.value,
                      })
                    }
                    value={listFilter.committee_id || ''}
                  >
                    <MenuItem value={''}>All committees</MenuItem>
                    {loadActionRegisterOptions.data.viewable_committees.map(
                      committee => (
                        <MenuItem value={committee.id} key={key(committee)}>
                          {committee.name}
                        </MenuItem>
                      ),
                    )}
                  </SelectControl>
                </Box>
              )}

              <FormControlLabel
                control={
                  <Checkbox
                    checked={listFilter.assigned_to_me}
                    onChange={e =>
                      setListFilter({
                        ...listFilter,
                        assigned_to_me: !!e.target.checked,
                      })
                    }
                  />
                }
                label="Assigned to me"
              />

              <FormControlLabel
                control={
                  <Switch
                    checked={listFilter.show_notes}
                    onChange={() => {
                      setListFilter({
                        ...listFilter,
                        show_notes: !listFilter.show_notes,
                        show_closed: true,
                      });
                    }}
                  />
                }
                label="Show closing notes"
              />
            </Stack>
          </Box>
          <Box>
            <Button
              variant="text"
              onClick={() => setListFilter(initialListFilterState)}
            >
              Reset filters
            </Button>
          </Box>
        </Stack>
      </Box>

      <ActionRegisterTable
        context={viewMode}
        showNotes={listFilter.show_notes}
        actions={loadActionRegisterItemList.data}
        onEdit={(id, mode) => {
          setEditMode(mode);
          dispatch(actions.loadActionRegisterItemRequest({ id }));
        }}
        onDelete={id => {
          dispatch(actions.deleteActionRegisterItemRequest({ id }));
        }}
        manageableCommitteeIds={loadActionRegisterOptions.data.managed_committees.map(
          committee => committee.id,
        )}
      />
      {viewMode !== 'default' && (
        <Box>
          <Grid container>
            <Grid item xs={12} md={calculateGridWidth}>
              <Button
                variant="outlined"
                fullWidth
                component={Link}
                to={`/organisation/${organisation_id}/actions/all`}
              >
                Browse and review all actions
              </Button>
            </Grid>
            {canExport && (
              <Grid item xs={12} md={calculateGridWidth}>
                <Button
                  variant="outlined"
                  startIcon={<RiDownload2Line />}
                  fullWidth
                  disabled={!loadActionRegisterItemList.data.length}
                  onClick={() => save()}
                >
                  Export
                </Button>
              </Grid>
            )}
            {canAddAction && (
              <Grid item xs={12} md={calculateGridWidth}>
                <Button
                  startIcon={<RiAddCircleFill />}
                  fullWidth
                  onClick={() => setFormOpen(true)}
                >
                  Add action
                </Button>
              </Grid>
            )}
          </Grid>
        </Box>
      )}
      <Dialog
        open={formOpen}
        onClose={() => setFormOpen(false)}
        fullWidth
        scroll="body"
        fullScreen={fullScreen}
        maxWidth={'md'}
      >
        <ActionRegisterForm
          committeePath={committeePath}
          action={loadActionRegisterItem.data}
          organisation_id={+organisation_id}
          committee_id={+committee_id}
          meeting_id={meeting_id}
          saving={saveActionRegisterItem.loading}
          onSave={values => {
            dispatch(actions.saveActionRegisterItemRequest(values));
          }}
          committees={loadActionRegisterOptions.data.managed_committees}
          onClose={() => setFormOpen(false)}
        />
      </Dialog>

      <Dialog
        open={editStatusFormOpen}
        onClose={() => setEditStatusFormOpen(false)}
        fullWidth
        scroll="body"
        fullScreen={fullScreen}
        maxWidth={'md'}
      >
        <ActionRegisterStatusForm
          action={loadActionRegisterItem.data}
          saving={saveActionRegisterItem.loading}
          onSave={values => {
            dispatch(actions.saveActionRegisterItemRequest(values));
          }}
          onClose={() => setEditStatusFormOpen(false)}
        />
      </Dialog>

      <Dialog
        open={editDetailsFormOpen}
        onClose={() => setEditDetailsFormOpen(false)}
        fullWidth
        fullScreen={fullScreen}
        maxWidth={'md'}
        scroll="body"
      >
        <ActionRegisterDetailsForm
          action={loadActionRegisterItem.data}
          saving={saveActionRegisterItem.loading}
          committees={loadActionRegisterOptions.data.managed_committees}
          onSave={values => {
            dispatch(actions.saveActionRegisterItemRequest(values));
          }}
          onClose={() => setEditDetailsFormOpen(false)}
        />
      </Dialog>

      <Dialog
        open={editAssigneesFormOpen}
        onClose={() => setEditAssigneesFormOpen(false)}
        fullWidth
        scroll="body"
        fullScreen={fullScreen}
        maxWidth={'md'}
      >
        <ActionRegisterAssigneesForm
          committeePath={committeePath}
          action={loadActionRegisterItem.data}
          committees={loadActionRegisterOptions.data.managed_committees}
          saving={saveActionRegisterItem.loading}
          onSave={values => {
            dispatch(actions.saveActionRegisterItemRequest(values));
          }}
          onClose={() => setEditAssigneesFormOpen(false)}
        />
      </Dialog>
    </Stack>
  );
}
