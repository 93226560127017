import * as React from 'react';
import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import { RadioButton } from 'app/components/RadioButton';
import { Field, useFormikContext } from 'formik';

import { TextField } from 'formik-mui';
import moment from 'moment-timezone';
import { DateTimePickerWrapper } from 'app/components/DateTimePickerWrapper';
import { TitledField } from 'app/components/TitledField';
import { RequiredFieldIndicator } from 'app/components/RequiredFieldIndicator';
import { StartBoardValues } from './Wizard';

interface Props {
  previous: () => void;
  saving: boolean;
}

export function ScheduleMeeting(props: Props) {
  const { previous, saving } = props;
  const { setFieldValue, values, isValid, isSubmitting } =
    useFormikContext<StartBoardValues>();

  return (
    <Stack spacing={6}>
      <Typography variant={'h1'}>Schedule a meeting</Typography>
      <Box>
        <Stack spacing={4}>
          <Typography component={'p'}>
            Now your first board and members are set up you can schedule your
            first meeting of that Board and send out an invite to the members on
            that Board. If you are not ready to do this now (for example you
            want to add more papers and standing documents first), you can skip
            this step.
          </Typography>
        </Stack>
      </Box>
      <Box>
        <Grid container>
          <Grid item xs={12} md={6}>
            <RadioButton
              label={
                <Stack>
                  <Typography color={'primary'} variant={'h4'}>
                    Schedule a meeting now
                  </Typography>
                  <Typography color={'grey.700'}>
                    Make a start with scheduling a meeting. You can always
                    change it later.
                  </Typography>
                </Stack>
              }
              onChange={() => setFieldValue('schedule_meeting', true)}
              checked={values.schedule_meeting === true}
              sx={{ height: '100%' }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <RadioButton
              label={
                <Stack>
                  <Typography color={'primary'} variant={'h4'}>
                    Skip this step for now
                  </Typography>

                  <Stack spacing={1}>
                    <Typography
                      color={'grey.700'}
                      sx={{ textDecoration: 'none' }}
                    >
                      This will option will only create your committee. You can
                      schedule and manage your meetings later at any time by
                      navigating to Meetings in the menu
                    </Typography>
                  </Stack>
                </Stack>
              }
              onChange={() => setFieldValue('schedule_meeting', false)}
              checked={values.schedule_meeting === false}
            />
          </Grid>
        </Grid>
      </Box>
      <Box>
        {!!values.schedule_meeting && (
          <Box>
            <Stack spacing={4}>
              <TitledField title="Committee name">
                <Typography>{values.committee_name}</Typography>
              </TitledField>
              <Box>
                <RequiredFieldIndicator />
                <TitledField title="Meeting name *">
                  <Field
                    component={TextField}
                    name={'meeting.name'}
                    label={'Meeting name'}
                    fullWidth
                  />
                </TitledField>
              </Box>
              <TitledField title="Description">
                <Field
                  component={TextField}
                  name={'meeting.description'}
                  label={'Description'}
                  multiline
                  rows={4}
                  fullWidth
                />
              </TitledField>

              <Box>
                <Grid container>
                  <Grid item xs={12} md={6}>
                    <TitledField title="Date *">
                      <DateTimePickerWrapper
                        name={'meeting.date_start'}
                        sx={{ width: '100%' }}
                        format={'DD/MM/YYYY hh:mm a'}
                        minutesStep={15}
                        onChange={(date, context) => {
                          if (date) {
                            // add length in hours to start date
                            const newDate = date
                              .clone()
                              .add(values.meeting.length, 'hours');
                            setFieldValue('meeting.date_end', newDate);
                          }
                        }}
                      />
                    </TitledField>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TitledField title="Length *">
                      <Field
                        component={TextField}
                        id="length"
                        name="meeting.length"
                        label="Length (hours)"
                        type="number"
                        InputProps={{
                          inputProps: { min: 0.5, step: 0.5 },
                        }}
                        fullWidth
                        onChange={e => {
                          const length = parseFloat(e.target.value); // Parse the input value to a float
                          // set date_end to be date_start + length as moment object
                          const newDate = moment(values.meeting.date_start)
                            .clone()
                            .add(length, 'hours');
                          setFieldValue('meeting.date_end', newDate);
                          setFieldValue('meeting.length', length);
                        }}
                      />
                    </TitledField>
                  </Grid>
                </Grid>
              </Box>

              <TitledField title="Location">
                <Field
                  component={TextField}
                  name={'meeting.location'}
                  label={'Location / URL'}
                  fullWidth
                />
              </TitledField>
            </Stack>
          </Box>
        )}
      </Box>
      <Box>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <Button fullWidth variant={'outlined'} onClick={() => previous()}>
              Previous
            </Button>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              fullWidth
              disabled={!isValid || isSubmitting || saving}
              type={'submit'}
            >
              Confirm: Ready to go
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Stack>
  );
}
