/**
 *
 * OrganisationProfile
 *
 */
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useOrganisationProfileSlice } from './slice/hook';
import { Helmet } from 'react-helmet-async';
import {
  Box,
  Container,
  Divider,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import { IOrganisation } from 'types/types';
import { useSnackbar } from 'notistack';
import { useEffectOnce } from 'utils/useEffectOnce';
import { OrganisationForm } from './OrganisationForm';
import { LoadingIndicator } from 'app/components/LoadingIndicator';
import { selectOrganisationProfile } from './slice/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { ContactForm } from 'app/components/ContactForm';
import { BillingInfo } from './BillingInfo';
import { useHasPermission } from 'app/providers/AuthProvider/useHasPermission';
import { OrganisationDetails } from './OrganisationDetails';
import { useHasChanged } from 'utils/usePrevious';
import { SectionHeader } from 'app/components/SectionHeader';

interface Props {
  organisation: IOrganisation;
}

export function OrganisationProfile(props: Props) {
  const { organisation } = props;
  const { loadOrganisationProfile, saveOrganisationProfile, sendContactForm } =
    useSelector(selectOrganisationProfile);
  const { actions } = useOrganisationProfileSlice();
  const dispatch = useDispatch();
  const snackbar = useSnackbar();
  const [resetForm, setResetForm] = useState<boolean>(false);
  const { hasPermission } = useHasPermission();

  const canManage = hasPermission(
    'manage-organisation-profile',
    'organisation',
    organisation.id,
  );
  const load = useCallback(() => {
    dispatch(actions.loadOrganisationProfileRequest({ id: organisation.id }));
  }, [organisation]);

  const saveOrganisationChanged = useHasChanged(
    saveOrganisationProfile.loading,
  );
  const sendContactFormChanged = useHasChanged(sendContactForm.loading);

  useEffect(() => {
    if (saveOrganisationChanged && !saveOrganisationProfile.loading) {
      if (!!saveOrganisationProfile.error) {
        snackbar.enqueueSnackbar('Error saving organisation profile', {
          variant: 'error',
        });
        return;
      }
      snackbar.enqueueSnackbar('Organisation profile saved', {
        variant: 'success',
      });
      load();
    }
  });

  useEffect(() => {
    if (sendContactFormChanged && !sendContactForm.loading) {
      if (!!sendContactForm.error) {
        snackbar.enqueueSnackbar('Error sending message', {
          variant: 'error',
        });
        return;
      }
      snackbar.enqueueSnackbar('Message sent successfully.', {
        variant: 'success',
      });
      setResetForm(true);
    }
  }, [
    sendContactFormChanged,
    sendContactForm.loading,
    sendContactForm.error,
    snackbar,
  ]);

  useEffect(() => {
    if (resetForm) setResetForm(false);
  }, [resetForm]);

  useEffectOnce(() => {
    load();
    return () => {
      snackbar.closeSnackbar();
    };
  });

  if (!loadOrganisationProfile.data) return <LoadingIndicator height={300} />;

  return (
    <>
      <Helmet title={'Organisation profile'}>
        <meta name="description" content="Manage your organisation details" />
      </Helmet>
      <SectionHeader title="Organisation Profile" />
      <Container>
        <Box sx={{ my: 4 }}>
          <Stack spacing={6}>
            <Typography variant="h3">Organisation Profile</Typography>
            <Box>
              {!canManage && (
                <OrganisationDetails
                  organisation={loadOrganisationProfile.data}
                />
              )}

              {canManage && (
                <OrganisationForm
                  organisation={loadOrganisationProfile.data}
                  saving={saveOrganisationProfile.loading}
                  onSave={values =>
                    dispatch(actions.saveOrganisationProfileRequest(values))
                  }
                />
              )}
            </Box>
            <Divider />
            <Box>
              <Grid container spacing={8}>
                {canManage && (
                  <Grid item xs={12} md={6}>
                    <BillingInfo />
                  </Grid>
                )}

                <Grid item xs={12} md={canManage ? 6 : 12}>
                  <ContactForm
                    title="Have a problem or query?"
                    subtext="Please let us know if you experience any issues with the
                      platform or have any queries or suggestions for our team."
                    width={canManage ? 4 : 2}
                    sending={sendContactForm.loading}
                    reset={resetForm}
                    onSubmit={payload =>
                      dispatch(
                        actions.sendContactFormRequest({
                          ...payload,
                          organisation_name: organisation.name,
                        }),
                      )
                    }
                  />
                </Grid>
              </Grid>
            </Box>
          </Stack>
        </Box>
      </Container>
    </>
  );
}
