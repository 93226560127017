import React, { useCallback, useEffect, useMemo } from 'react';
import { Box, Button, Grid, MenuItem, Stack, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useMeetingsSlice } from './slice/hook';
import { selectLoadMeetings } from './slice/selectors';
import { useEffectOnce } from 'utils/useEffectOnce';
import moment from 'moment-timezone';
import { useSettings } from 'app/providers/SettingsProvider';
import { SelectControl } from 'app/components/SelectControl';
import { useLoadCommittees } from 'utils/useLoadCommittees';
import { MeetingCard } from './MeetingCard';
import { MeetingsCarousel } from './MeetingsCarousel';
import key from 'weak-key';
import { IOrganisation } from 'types/types';

interface Props {
  organisation: IOrganisation;
  context: 'upcoming' | 'past';
}

export function MeetingsCardList({ organisation, context }: Props) {
  const { actions: meetingsActions } = useMeetingsSlice();
  const loadMeetings = useSelector(selectLoadMeetings);
  const dispatch = useDispatch();
  const { settings, setSetting } = useSettings();
  const selectedCommittee = settings?.selectedCommittee || '';

  const load = useCallback(() => {
    dispatch(
      meetingsActions.loadMeetingsRequest({
        organisation_id: organisation.id,
        committee_id: selectedCommittee || '',
      }),
    );
  }, [dispatch, meetingsActions, organisation.id, selectedCommittee]);

  const { reloadCommittees, loadCommittees } = useLoadCommittees(
    organisation.id,
  );

  useEffectOnce(() => {
    reloadCommittees();
    load();
  });

  useEffect(() => {
    load();
  }, [load]);

  const meetings = loadMeetings.data || [];
  const now = moment();
  const isUpcoming = (meeting: any) =>
    moment(meeting.date_start).isAfter(now) ||
    meeting.historical_status === 'current';

  const isPast = (meeting: any) => moment(meeting.date_end).isBefore(now);
  const filteredMeetings = useMemo(() => {
    const contextFilter =
      context === 'upcoming'
        ? meetings.filter(isUpcoming)
        : meetings.filter(isPast);

    return selectedCommittee
      ? contextFilter.filter(
          meeting => `${meeting.committee?.id}` === `${selectedCommittee}`,
        )
      : contextFilter;
  }, [loadMeetings.data, context, selectedCommittee]);

  const recentMeetings = useMemo(() => {
    const now = moment();
    const sevenDaysAgo = moment().subtract(7, 'days');
    return (
      loadMeetings.data
        ?.filter(meeting => {
          const meetingDate = moment(meeting.date_end);
          return meetingDate.isBetween(sevenDaysAgo, now, undefined, '[]');
        })
        .sort((a, b) => moment(b.date_end).diff(moment(a.date_end))) || []
    );
  }, [loadMeetings.data]);

  const handleCommitteeChange = (
    event: React.ChangeEvent<{ value: unknown }>,
  ) => {
    setSetting('selectedCommittee', event.target.value as string);
  };

  const handleResetCommittee = () => {
    setSetting('selectedCommittee', '');
  };

  const allCommittees = useMemo(
    () => loadCommittees?.data || [],
    [loadCommittees?.data],
  );

  const content = () => {
    if (filteredMeetings.length > 0) {
      return (
        <>
          {context === 'upcoming' ? (
            <MeetingsCarousel meetings={filteredMeetings} />
          ) : (
            <Box>
              <Grid container spacing={4}>
                {filteredMeetings.map(meeting => (
                  <Grid item key={meeting.id} xs={12} md={6} lg={4}>
                    <MeetingCard meeting={meeting} />
                  </Grid>
                ))}
              </Grid>
            </Box>
          )}
        </>
      );
    } else {
      return <Typography>No meetings available</Typography>;
    }
  };

  return (
    <Stack spacing={6}>
      {allCommittees.length > 1 && (
        <Box>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <SelectControl
              sx={{ minWidth: 200 }}
              onChange={handleCommitteeChange}
              value={selectedCommittee}
            >
              <MenuItem value="">Showing all committees</MenuItem>
              {allCommittees.map(committee => (
                <MenuItem key={committee.id} value={committee.id}>
                  {committee.name}
                </MenuItem>
              ))}
            </SelectControl>
            <Button variant="text" onClick={handleResetCommittee}>
              Reset filter
            </Button>
          </Stack>
        </Box>
      )}
      <Typography variant="h3">
        {context === 'upcoming'
          ? 'Upcoming & Recent Meetings'
          : 'Past Meetings'}
      </Typography>
      {content()}
      {context === 'upcoming' && (
        <Stack spacing={4}>
          <Typography variant="h3">Latest Meetings</Typography>
          {!!recentMeetings.length ? (
            <Stack>
              <Typography>
                These meetings have passed, but minutes, transcriptions, and
                documents may still be of current use to you.
              </Typography>
              <Box>
                <Grid container spacing={4}>
                  {recentMeetings.map(meeting => (
                    <Grid item key={key(meeting)} xs={12} md={6} lg={4}>
                      <MeetingCard meeting={meeting} />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Stack>
          ) : (
            <Typography>
              {"You haven't got meetings in the last 7 days."}
            </Typography>
          )}
        </Stack>
      )}
    </Stack>
  );
}
