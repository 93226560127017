import React, { useCallback, useEffect, useState } from 'react';
import * as Yup from 'yup';
import {
  Box,
  Button,
  ButtonBase,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Stack,
  TextField as MuiTextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import { ToggleButton } from '../ToggleButton';
import { CustomBadge } from '../CustomBadge';
import { HelpModal } from '../HelpModal';
import { RiskSaveBar } from '../RiskSaveBar';
import { Field, Form, Formik, FormikContextType } from 'formik';
import { IRiskArea, IRiskRegisterItem } from 'types/types';
import { EditableField } from '../EditableField';
import { RiskTips } from './RiskTips';
import { ResponsiveRow } from './ResponsiveRow';
import { useRiskColourName } from 'utils/useRiskColourName';
import { RiskLevelSelector } from '../RiskLevelSelector';
import { ReactComponent as PinFill } from './assets/pin_fill.svg';
import { ReactComponent as PinLine } from './assets/pin_line.svg';
import { ReactComponent as ArrowUp } from './assets/arrow_up.svg';
import { ReactComponent as ArrowDown } from './assets/arrow_down.svg';

import moment from 'moment';
import { Select } from 'formik-mui';
import { KeyboardArrowDownSharp } from '@mui/icons-material';
import key from 'weak-key';
import { Link } from 'react-router-dom';
import { useConfirm } from 'material-ui-confirm';

interface Props {
  item: IRiskRegisterItem;
  open: boolean;
  onSave: (
    payload: IRiskRegisterItem & { permanently_delete?: boolean },
  ) => void;
  toggleOpen: () => void;
  saving: boolean;
  canManage: boolean;
  memberNames?: string[];
  risk_areas: IRiskArea[];
}

const schema = Yup.object().shape({
  name: Yup.string().required('This field is required'),
  risk_area_code: Yup.string().required('This field is required'),
  impact_text: Yup.string().required('This field is required').nullable(),
  control_measures_text: Yup.string()
    .required('This field is required')
    .nullable(),
  owner_name: Yup.string().required('This field is required').nullable(),
  score_impact: Yup.number().required('This field is required'),
  score_likelihood: Yup.number().required('This field is required'),
  score_impact_mitigated: Yup.number().required('This field is required'),
});

export function RiskRegisterItem(props: Props) {
  const {
    risk_areas,
    open,
    toggleOpen,
    item,
    onSave,
    saving,
    canManage,
    memberNames = [],
  } = props;

  const [mode, setMode] = useState<string>('view');

  const [archiveConfirmedChecked, setArchiveConfirmedChecked] = useState(false);
  const [permanentlyDelete, setPermanentlyDelete] = useState(false);

  const confirm = useConfirm();

  useEffect(() => {
    if (mode === 'archive') {
      setArchiveConfirmedChecked(false);
    }
  }, [mode]);

  const calcMitigatedRisk = item.score_impact_mitigated * item.score_likelihood;
  const mitigatedRiskAverage = Math.max(
    (item.score_impact_mitigated * item.score_likelihood) / 5,
    1,
  );

  const isEditMode = mode === 'edit' && open;

  const getRiskColourData = useRiskColourName();

  const { colour } = getRiskColourData(mitigatedRiskAverage, 'mitigated');
  const theme = useTheme();
  const mdViewUp = useMediaQuery(theme.breakpoints.up('md'));

  const findCategory = val => {
    const category = risk_areas.find(a => a.code === val);
    return category?.name ? (
      <Typography variant="body2">{category.name}</Typography>
    ) : (
      <Typography variant="body2" fontStyle={'italic'}>
        Removed
      </Typography>
    );
  };

  const formatName = fullName => {
    const names = fullName.split(' ');
    if (names.length > 1) {
      const formattedSecondName = names[1].charAt(0).toUpperCase();
      return `${names[0]} ${formattedSecondName}.`;
    }
    return fullName;
  };

  const content = useCallback(
    (formik: FormikContextType<any>) => {
      switch (mode) {
        case 'view':
        case 'edit':
          return (
            <Stack spacing={2}>
              {isEditMode && canManage && (
                <ResponsiveRow
                  title={<>Risk category</>}
                  riskHelpModal={<></>}
                  riskLevelSelector={
                    <FormControl fullWidth>
                      <Field
                        name="risk_area_code"
                        component={Select}
                        IconComponent={KeyboardArrowDownSharp}
                        renderValue={value => {
                          if (!value) return '--- Select risk category ---';
                          const area = risk_areas.find(
                            area => area.code === value,
                          );
                          return area?.name;
                        }}
                        displayEmpty
                        sx={{
                          height: 40,
                          borderRadius: 2,
                          backgroundColor: 'white',
                          '& .MuiInput-icon': {
                            fontSize: 30,
                            color: 'primary.main',
                            fontWeight: 900,
                          },
                        }}
                      >
                        <MenuItem value={''}>
                          --- Select risk category ---
                        </MenuItem>
                        {risk_areas.map(area => (
                          <MenuItem key={key(area)} value={area.code}>
                            {area.name}
                          </MenuItem>
                        ))}
                      </Field>
                    </FormControl>
                  }
                />
              )}
              <ResponsiveRow
                title={<>Likelihood / is it likely</>}
                riskHelpModal={
                  <HelpModal
                    iconTitle="Find more about risk likelihood"
                    name="score_likelihood"
                    buttonText={mdViewUp ? 'How to estimate likelihood' : ''}
                  />
                }
                riskLevelSelector={
                  <RiskLevelSelector
                    name="score_likelihood"
                    readOnly={!canManage}
                  />
                }
              />
              <ResponsiveRow
                title={
                  <>
                    Impact <i>before</i> controls
                  </>
                }
                riskHelpModal={
                  <HelpModal
                    iconTitle="Find more about impact before controls"
                    name="score_impact"
                    buttonText={mdViewUp ? 'Estimating impact' : ''}
                  />
                }
                riskLevelSelector={
                  <RiskLevelSelector
                    name="score_impact"
                    readOnly={!canManage}
                  />
                }
              />
              <Box>
                <Stack spacing={0.5}>
                  <Box>
                    <Stack direction={'row'} spacing={1} alignItems={'center'}>
                      <Typography fontWeight={'bold'}>Impact</Typography>
                      <HelpModal
                        iconTitle="Find more about impact"
                        name="impact_text"
                      />
                    </Stack>
                  </Box>
                  <Box>
                    <EditableField
                      text={item.impact_text}
                      bold={false}
                      name="impact_text"
                      isEdit={isEditMode}
                      multiline
                    />
                  </Box>
                </Stack>
              </Box>
              <Box>
                <Stack spacing={0.5}>
                  <Box>
                    <Stack direction={'row'} spacing={1} alignItems={'center'}>
                      <Typography fontWeight={'bold'}>
                        Control measures
                      </Typography>
                      <HelpModal
                        iconTitle="Find more about control measures"
                        name="control_measures_text"
                      />
                    </Stack>
                  </Box>
                  <Box>
                    <EditableField
                      bold={false}
                      text={item.control_measures_text}
                      name="control_measures_text"
                      isEdit={isEditMode}
                      multiline
                    />
                  </Box>
                </Stack>
              </Box>
              <ResponsiveRow
                title={
                  <>
                    Impact <i>after</i> controls
                  </>
                }
                riskHelpModal={
                  <HelpModal
                    iconTitle="Find more about impact after controls"
                    name="score_impact_mitigated"
                    buttonText={mdViewUp ? 'How to rank mitigated impact' : ''}
                  />
                }
                riskLevelSelector={
                  <RiskLevelSelector
                    name="score_impact_mitigated"
                    readOnly={!canManage}
                  />
                }
              />
              {!!canManage && (
                <Box sx={{ pt: 2 }}>
                  <RiskSaveBar
                    size="small"
                    lastSaved={item.updated_at}
                    saving={saving}
                  />
                </Box>
              )}
              {!!item.is_archived && (
                <Box sx={{ py: 2 }}>
                  <Stack
                    direction={'row'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                  >
                    <Typography sx={{ color: '#71718B' }}>
                      Archived on {moment(item.archived_at).format('D MMM Y')}{' '}
                      by {item.archived_by}
                      <Button
                        size={'small'}
                        color={'error'}
                        variant={'text'}
                        onClick={() => {
                          confirm({
                            title: 'Delete permanently',
                            description:
                              'Are you sure you want to permanently delete this risk? This action is not reversible.',
                          })
                            .then(() => {
                              onSave({
                                ...item,
                                permanently_delete: true,
                              });
                            })
                            .catch(() => {});
                        }}
                      >
                        Delete permanently
                      </Button>
                    </Typography>
                    <Button
                      sx={{ ml: 'auto' }}
                      component={Link}
                      to={'../add'}
                      state={{ template: item }}
                    >
                      Use as a template for a new risk
                    </Button>
                  </Stack>
                </Box>
              )}
            </Stack>
          );
        case 'archive':
          return (
            <Stack spacing={2}>
              <Typography variant={'h3'}>Archive risk</Typography>
              <Typography>
                Are you sure you want to archive this risk? This will remove it
                from your risk register.
              </Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    edge="start"
                    checked={archiveConfirmedChecked}
                    onClick={e => {
                      if (archiveConfirmedChecked) setPermanentlyDelete(false);
                      setArchiveConfirmedChecked(!archiveConfirmedChecked);
                    }}
                    color="primary"
                  />
                }
                label="Click here to confirm you want to archive this risk"
              />
              {archiveConfirmedChecked && (
                <>
                  <Typography variant={'body1'}>
                    If you want to delete the risk from your system permanently,
                    check the box below. Please note, this action is not
                    reversible.
                  </Typography>

                  <FormControlLabel
                    control={
                      <Checkbox
                        edge="start"
                        checked={permanentlyDelete}
                        onClick={e => {
                          setPermanentlyDelete(!permanentlyDelete);
                        }}
                        color="primary"
                      />
                    }
                    label="Permanently delete this risk?"
                  />
                </>
              )}
              <Stack direction={'row'} spacing={2}>
                <Button
                  disabled={!archiveConfirmedChecked}
                  onClick={() => {
                    onSave({
                      ...item,
                      is_archived: true,
                      permanently_delete: permanentlyDelete,
                    });
                    setMode('view');
                  }}
                >
                  {permanentlyDelete ? 'Delete risk' : 'Archive risk'}
                </Button>
              </Stack>
            </Stack>
          );
        case 'tips':
          return <RiskTips />;
      }
    },
    [
      mode,
      item,
      canManage,
      risk_areas,
      archiveConfirmedChecked,
      saving,
      permanentlyDelete,
    ],
  );

  return (
    <>
      <Box
        sx={{
          border: '1px solid #E8E8E8',
          borderLeft: open ? '5px solid #F2F2F2' : `5px solid ${colour}`,
        }}
      >
        <Formik
          initialValues={
            item
              ? {
                  ...item,
                }
              : ({
                  id: undefined,
                  name: '',
                  impact_text: '',
                  owner_name: '',
                  control_measures_text: '',
                  score_impact: null,
                  score_likelihood: null,
                  score_impact_mitigated: null,
                  due_date: null,
                } as IRiskRegisterItem)
          }
          validationSchema={schema}
          validateOnChange
          onSubmit={(values, { setSubmitting }) => {
            onSave({ ...values });
          }}
        >
          {formik => {
            const { setSubmitting, isSubmitting, values } = formik;
            if (isSubmitting && !saving) setSubmitting(false);
            return (
              <Form>
                <Stack>
                  <Box
                    sx={{
                      position: 'relative',
                      minHeight: 60,
                      borderBottom: open && '1px solid #E8E8E8',
                      textDecoration: 'none',
                      color: 'common.midnight',
                    }}
                  >
                    <ButtonBase
                      onClick={toggleOpen}
                      sx={{
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                      }}
                    />

                    <Stack
                      direction={'row'}
                      spacing={0}
                      alignItems={'center'}
                      minHeight={60}
                    >
                      <Box>
                        <Stack sx={{ width: '50px' }} alignItems={'center'}>
                          {open ? <ArrowUp /> : <ArrowDown />}
                        </Stack>
                      </Box>
                      <Box width={'100%'}>
                        <Stack
                          direction={'row'}
                          width={'100%'}
                          alignItems="center"
                        >
                          <Box sx={{ flex: 1 }}>
                            <Grid
                              container
                              justifyContent={'start'}
                              alignItems={'center'}
                            >
                              <Grid item xs={mdViewUp ? 6 : 7}>
                                <EditableField
                                  text={item.name}
                                  name="name"
                                  isEdit={isEditMode}
                                  label={'Risk name'}
                                  bold
                                />
                              </Grid>
                              <Grid item xs={mdViewUp ? 3 : 5}>
                                {item.is_archived ? (
                                  <>{findCategory(item.risk_area_code)}</>
                                ) : (
                                  <EditableField
                                    name="owner_name"
                                    isEdit={isEditMode}
                                    text={
                                      mdViewUp
                                        ? item.owner_name
                                        : formatName(item.owner_name)
                                    }
                                    bold={false}
                                    type={'autocomplete'}
                                    options={memberNames}
                                    fullWidth
                                    freeSolo
                                    renderInput={params => (
                                      <MuiTextField
                                        {...params}
                                        label="Owner name"
                                        sx={{
                                          borderRadius: 2,
                                          backgroundColor: 'white',
                                          '& .MuiInput-icon': {
                                            fontSize: 30,
                                            color: 'primary.main',
                                            fontWeight: 900,
                                          },
                                        }}
                                      />
                                    )}
                                    onInputChange={(e, val, reason) => {
                                      formik.setFieldValue('owner_name', val);
                                    }}
                                  />
                                )}
                              </Grid>
                              {mdViewUp && (
                                <Grid item xs={3}>
                                  <Typography variant="body2">
                                    {item.is_archived ? (
                                      item.owner_name
                                    ) : (
                                      <>
                                        {moment(item.updated_at).format(
                                          'D MMM Y',
                                        )}
                                      </>
                                    )}
                                  </Typography>
                                </Grid>
                              )}
                            </Grid>
                          </Box>
                          <Box
                            sx={{
                              width: 150,
                            }}
                          >
                            {item.is_archived ? (
                              <Stack
                                direction={'row'}
                                justifyContent={'center'}
                                alignItems={'center'}
                              >
                                <Typography variant="body2">
                                  {moment(item.archived_at).format('D MMM Y')}
                                </Typography>
                              </Stack>
                            ) : (
                              <Box
                                sx={{
                                  width: '150px',
                                }}
                              >
                                <Stack
                                  justifyContent={'space-between'}
                                  direction={'row'}
                                  alignItems={'center'}
                                >
                                  <Box sx={{ width: 90 }}>
                                    <Stack
                                      direction={'row'}
                                      justifyContent={'center'}
                                    >
                                      <CustomBadge
                                        colour={colour}
                                        content={calcMitigatedRisk}
                                      />
                                    </Stack>
                                  </Box>
                                  {!!canManage && (
                                    <Box sx={{ width: 60 }}>
                                      <ToggleButton
                                        sx={{
                                          alignSelf: 'end',
                                          pointerEvents: canManage
                                            ? 'auto'
                                            : 'none',
                                        }}
                                        active={values.pinned}
                                        activeIcon={<PinFill />}
                                        inactiveIcon={<PinLine />}
                                        onToggle={() => {
                                          if (!canManage) return;
                                          const pinned = !values.pinned;
                                          formik.setFieldValue(
                                            'pinned',
                                            pinned,
                                          );
                                          if (!open)
                                            onSave({ ...item, pinned });
                                        }}
                                      />
                                    </Box>
                                  )}
                                </Stack>
                              </Box>
                            )}
                          </Box>
                        </Stack>
                      </Box>
                    </Stack>
                  </Box>

                  {open && (
                    <>
                      <Box sx={{ p: 2 }}>
                        <Stack spacing={4}>
                          {!!canManage && (
                            <Box>
                              <Stack
                                direction={'row'}
                                justifyContent={'space-between'}
                              >
                                <Box>
                                  <Stack direction={'row'} spacing={1}>
                                    {[
                                      { mode: 'view', label: 'Manage' },
                                      { mode: 'edit', label: 'Edit' },
                                      { mode: 'archive', label: 'Archive' },
                                      { mode: 'tips', label: 'Tips' },
                                    ].map(({ mode: m, label: l }) => (
                                      <Button
                                        key={m}
                                        variant="text"
                                        onClick={() => {
                                          setMode(m);
                                        }}
                                        sx={{
                                          textDecoration: 'none',
                                          py: 1,
                                          borderBottom: mode === m ? 2 : 'none',
                                          borderRadius: 0,
                                          color:
                                            mode === m
                                              ? 'common.midnight'
                                              : 'primary.main',
                                          '&:hover': {
                                            color: 'common.midnight',
                                            textDecoration: 'none',
                                          },
                                        }}
                                      >
                                        {l}
                                      </Button>
                                    ))}
                                  </Stack>
                                </Box>
                              </Stack>
                            </Box>
                          )}
                          <Box>{content(formik)}</Box>
                        </Stack>
                      </Box>
                    </>
                  )}
                </Stack>
              </Form>
            );
          }}
        </Formik>
      </Box>
    </>
  );
}
