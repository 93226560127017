/**
 *
 * ActionRegisterAssigneesForm
 *
 */
import React, { useMemo } from 'react';
import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { IActionRegisterItem } from 'types/types';
import { Form as FormikForm, Formik, useFormikContext } from 'formik';
import { RiCloseLine } from 'react-icons/ri';
import { ActionRegisterCommittee } from '../../slice/types';
import { AssignOwner } from '../ActionRegisterForm/steps/AssignOwner';
import { WizardContainer } from 'app/components/WizardContainer';
import { ResetAndSaveButtons } from 'app/components/ResetAndSaveButtons';
import { AssignOwnerField } from '../fields/AssignOwnerField';
import { DashedButton } from 'app/components/DashedButton';

interface Props {
  action: IActionRegisterItem;
  onSave: (payload: ActionRegisterFormType) => void;
  onClose: () => void;
  saving: boolean;
  committees: ActionRegisterCommittee[];
  committeePath?: string;
}

export type ActionRegisterFormType = Partial<IActionRegisterItem> & {
  member_ids?: (string | number)[];
};

export function ActionRegisterAssigneesForm(props: Props) {
  const { action, onSave, onClose, saving, committees, committeePath } = props;

  return (
    <Formik<ActionRegisterFormType>
      enableReinitialize
      validateOnMount
      initialValues={{
        ...action,
        member_ids: action.members.map(m => m.id),
      }}
      validateOnChange
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true);
        onSave({
          ...values,
        });
      }}
    >
      {formik => {
        const { setSubmitting, isSubmitting, values } = formik;
        if (isSubmitting && !saving) setSubmitting(false);
        const members =
          committees.find(c => c.id === values.committee_id)?.members || [];
        return (
          <FormikForm>
            <WizardContainer sx={{ py: 0, px: 0 }}>
              <DialogTitle>
                <Stack
                  direction={'row'}
                  alignItems={'center'}
                  justifyContent={'space-between'}
                >
                  <Typography variant="h2">Edit assignees</Typography>
                  <IconButton edge={'end'} size={'large'} onClick={onClose}>
                    <RiCloseLine />
                  </IconButton>
                </Stack>
              </DialogTitle>
              <DialogContent>
                <Stack spacing={4}>
                  <AssignOwnerField
                    committees={committees}
                    committeePath={`${committeePath}/${action.committee_id}`}
                  />
                </Stack>
              </DialogContent>
              {!!members.length && (
                <DialogActions>
                  <ResetAndSaveButtons saving={saving} />
                </DialogActions>
              )}
            </WizardContainer>
          </FormikForm>
        );
      }}
    </Formik>
  );
}
