import { Box, Button, MenuItem, Stack, Tab, Tabs } from '@mui/material';
import { useGetOrganisationMenuStructure } from 'app/pages/OrganisationPage/useGetOrganisationMenuStructure';
import { useOrganisationSelector } from 'app/providers/OrganisationSelector/useOrganisationSelector';
import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import key from 'weak-key';
import { OrganisationSelectLinks } from '../OrganisationSelectLinks';
interface Props {}

export function DrawerOrgMenu(props: Props) {
  const { tabs } = useGetOrganisationMenuStructure();
  const { currentOrganisationId } = useOrganisationSelector();

  return (
    <Stack spacing={0}>
      {tabs
        ?.filter(tab => !tab.hidden)
        .map((tab, index) => (
          <Box key={index}>
            {tab.links ? (
              tab.links
                .filter(link => !link.hidden)
                .map(link => (
                  <MenuItem
                    component={Link}
                    to={`/organisation/${currentOrganisationId}/${link.to}`}
                    key={key(link)}
                  >
                    {link.label}
                  </MenuItem>
                ))
            ) : (
              <MenuItem
                component={Link}
                to={`/organisation/${currentOrganisationId}/${tab.to}`}
                key={key(tab)}
              >
                {tab.label}
              </MenuItem>
            )}
          </Box>
        ))}
      <OrganisationSelectLinks />
    </Stack>
  );
}
