import { takeLatest } from 'redux-saga/effects';
import { documentsActions as actions } from '.';
import { createRequestSaga } from 'utils/saga';

const loadDocuments = createRequestSaga({
  actionType: actions.loadDocumentsRequest.type,
  url: 'documents',
  data: data => data,
  successAction: actions.loadDocumentsSuccess,
  failureAction: actions.loadDocumentsFailed,
});

const loadDocumentCategories = createRequestSaga({
  actionType: actions.loadDocumentCategoriesRequest.type,
  url: 'documents/categories',
  data: data => data,
  successAction: actions.loadDocumentCategoriesSuccess,
  failureAction: actions.loadDocumentCategoriesFailed,
});

const loadExpiringDocuments = createRequestSaga({
  actionType: actions.loadDocumentsRequest.type,
  url: 'documents',
  data: data => ({ ...data, expiring: true }),
  successAction: actions.loadExpiringDocumentsSuccess,
  failureAction: actions.loadExpiringDocumentsFailed,
});

export function* documentsSaga() {
  yield takeLatest(actions.loadDocumentsRequest.type, loadDocuments);
  yield takeLatest(
    actions.loadExpiringDocumentsRequest.type,
    loadExpiringDocuments,
  );
  yield takeLatest(
    actions.loadDocumentCategoriesRequest.type,
    loadDocumentCategories,
  );
}
