import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) => state.templates || initialState;

export const selectTemplates = createSelector([selectSlice], state => state);
export const selectLoadTemplates = createSelector(
  [selectSlice],
  state => state.loadTemplates,
);
