import { takeLatest } from 'redux-saga/effects';
import { templatesActions as actions } from '.';
import { createRequestSaga } from 'utils/saga';

const loadTemplates = createRequestSaga({
  actionType: actions.loadTemplatesRequest.type,
  url: 'documents/plan-templates',
  data: data => data,
  successAction: actions.loadTemplatesSuccess,
  failureAction: actions.loadTemplatesFailed,
});

export function* templatesSaga() {
  yield takeLatest(actions.loadTemplatesRequest.type, loadTemplates);
}
